<div class="PNT-container">
  <div class="PNT-container__content">
    <div class="PNT-container__content__left">
      <span class="PNT-container__content__title">Oops!</span>
      <span class="PNT-container__content__label"
        >The page you’re looking for cannot be found.</span
      >
      <div class="PNT-container__content__button">
        <button
          mat-stroked-button
          class="return-home-btn"
          (mouseover)="
            imgSrcLearMoreArrow =
              'assets/images/arrow-right-learn-more-hover.png'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/images/arrow-forward.png'"
          (click)="goToPage('./')"
        >
          <div class="return-home-btn-content">
            <div class="return-home-btn-content__img">
              <img
                [src]="imgSrcLearMoreArrow"
                alt=""
                srcset=""
                class="img-arrow"
              />
            </div>
            <div class="return-home-btn-content__title">return Home</div>
          </div>
        </button>
      </div>
    </div>
    <div class="PNT-container__content__right">
      <img src="assets/images/404.webp" alt="page not found" srcset="">
    </div>
  </div>
  <div class="contact-support">
    <div class="contact-support__content" (click)="showHelpLine()">
      <div class="contact-support__content__text">Contact Support</div>
      <div class="contact-support__content__icon">
        <img src="assets/icon/support-agent.svg" alt="" srcset="" />
      </div>
    </div>
  </div>
</div>
