<div class="CS-container">
  <div class="CS-container__content">
    <div class="CS-container__content__left">
      <span class="CS-container__content__title"
        >Coming <span class="ime-color-red">Soon...</span></span
      >
      <span class="CS-container__content__label"
        >The page you’re looking for is currently under construction.</span
      >
      <div class="CS-container__content__button">
        <button
          mat-stroked-button
          class="return-home-btn"
          (mouseover)="
                imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
              "
              (mouseout)="
                imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'
              "
          (click)="goToPage('./')"
        >
          <div class="return-home-btn-content">
            <div class="return-home-btn-content__img">
              <img
                [src]="imgSrcLearMoreArrow"
                alt=""
                srcset=""
                class="img-arrow"
              />
            </div>
            <div class="return-home-btn-content__title">return Home</div>
          </div>
        </button>
      </div>
    </div>
    <div class="CS-container__content__right">
      <img src="assets/images/under-construction.webp" alt="page not found" srcset="" />
    </div>
  </div>
  <div class="contact-support">
    <div class="contact-support__content" (click)="showHelpLine()">
      <div class="contact-support__content__text">Contact Support</div>
      <div class="contact-support__content__icon">
        <img src="assets/icon/support-agent.svg" alt="" srcset="" />
      </div>
    </div>
  </div>
</div>
