import {
  animate,
  state,
  style,
  transition,
  trigger,
} from "@angular/animations";
import { DOCUMENT } from "@angular/common";
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { NavigationEnd, Router } from "@angular/router";
import * as AOS from "aos";
import { filter } from "rxjs/operators";
import { ROUTER_BASE_PATH } from "../../constants/router-path";

enum VisibilityState {
  Visible = "visible",
  Hidden = "hidden",
}

@Component({
  selector: "ime-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],

  animations: [
    trigger("toggle", [
      state(
        VisibilityState.Hidden,
        style({
          transform: "translateY(-100px)",
        })
      ),
      state(
        VisibilityState.Visible,
        style({
          transform: "translateY(0px)",
        })
      ),
      transition("* => *", animate("300ms ease-in-out")),
    ]),
  ],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public isVisible = true;
  userLogin: boolean = false; //TODO false by default
  userDetails = {
    userName: "",
    ssid: "",
  };

  headerMenuItems = [
    {
      menuItem: "Send & Receive",
      redirect: null,
      class: "sub-menu sendReceive",
      subMenu: [
        {
          name: "How it works",
          link: ROUTER_BASE_PATH.HOW_IT_WORKS,
        },
        {
          name: "Remittance",
          link: ROUTER_BASE_PATH.REMITTANCE,
        },
        {
          name: "Wallet Transfer",
          link: ROUTER_BASE_PATH.WALLET_TRANSFER,
        },
        {
          name: "Bank & Transfers",
          link: ROUTER_BASE_PATH.BANK_TRANSFER,
        },
      ],
    },
    {
      menuItem: "Pay with IME Pay",
      redirect: null,
      class: "sub-menu pay",
      subMenu: [
        {
          name: "Ways to pay",
          link: ROUTER_BASE_PATH.WAY_TO_PAY,
        },
        {
          name: "Pay For IME Pay Services",
          link: ROUTER_BASE_PATH.PAY_SERVICE,
        },
        {
          name: "Pay in Apps and Online",
          link: ROUTER_BASE_PATH.PAY_ONLINE_IN_APPS,
        },
        {
          name: "Pay in Stores",
          link: ROUTER_BASE_PATH.PAY_IN_STORE,
        },
        {
          name: "IME Pay Visa Card",
          link: ROUTER_BASE_PATH.PAY_THROUGH_CARD,
        },
      ],
    },
    {
      menuItem: "Become an IME Pay Agent",
      redirect: "coming-soon",
      subMenu: null,
    },
  ];

  buttonRequired = {
    login: true,
    getApp: true,
  };

  eventOptions = {
    capture: true,
    passive: true,
  };
  windowOffSetValues = [];
  @Input() inputSideNav: MatSidenav;

  constructor(
    private _router: Router,
    @Inject(DOCUMENT) private _document: Document
  ) {}
  changeHeaderCss = false;

  scrollHandler = (event) => {
    let value = event.srcElement["scrollTop"] || event.path[1].scrollY;
    // if(value) {

    //   this.windowOffSetValues.push(value);
    //   this.windowOffSetValues = this.windowOffSetValues.splice(-3);
    // }
    // this.isVisible =
    //   this.windowOffSetValues[this.windowOffSetValues.length - 1] <
    //   this.windowOffSetValues[this.windowOffSetValues.length - 2];
    // !this.isVisible ?

    if (value > 5) {
      this.changeHeaderCss = true;
    } else {
      this.changeHeaderCss = false;
    }

    AOS.refresh();
    // : '';
  };

  ngAfterViewInit(): void {
    this.changeHeaderCss = false;
    this._document.addEventListener(
      "scroll",
      this.scrollHandler,
      <any>this.eventOptions
    );
  }
  showMenuList = false;

  showMenu = true;

  goToLink(url) {
    window.open(url, "_blank");
  }

  ngOnInit(): void {
    // this._authService.isUserLoggedIn$.subscribe((response) => {
    //   this.userLogin = response;
    // });
    // this._authService.userLoginDetails$.subscribe((response) => {
    //   this.userDetails.userName = response?.fullName;
    //   this.userDetails.ssid = response?.walletBalance;
    // });
    // current url
    this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event.url == "/get-app") {
          this.showMenu = false;
          this.buttonRequired.getApp = false;
        } else {
          this.showMenu = true;
          this.buttonRequired.getApp = true;
        }
      });
  }

  @Output() closeSidenavEvent = new EventEmitter();
  openMenu() {
    this.inputSideNav.open();
    this.closeSidenavEvent.emit(true);
  }
}
