<!-- <header [@toggle]="isVisible ? 'visible' : 'hidden'"> -->
<header [ngClass]="{ 'header-scrolled': changeHeaderCss }">
  <mat-toolbar class="toolbar-container">
    <div class="toolbar-container__logo-menus">
      <div class="header-logo">
        <img
          class="logo"
          src="assets/logo/ime-main.svg"
          alt="logo of IMEPay"
          [routerLink]="'/'"
        />
      </div>
      <div class="container-menus" *ngIf="showMenu">
        <div class="menu-list" *ngFor="let menu of headerMenuItems">
          <div class="menus">
            <a mat-flat-button class="menu" *ngIf="!menu.redirect"
              >{{ menu.menuItem
              }}<img
                *ngIf="menu.subMenu"
                src="assets/icon/down.svg"
                alt=""
                srcset=""
            /></a>
            <a
              mat-flat-button
              class="menu menu-redirect"
              *ngIf="menu.redirect"
              (click)="goToLink('https://blog.imepay.com.np/become-an-ime-agent/')"
              >{{ menu.menuItem
              }}<img
                *ngIf="menu.subMenu"
                src="assets/icon/down.svg"
                alt=""
                srcset=""
            /></a>
            <div [ngClass]="menu.class" *ngIf="menu.subMenu">
              <div class="sub-menu__box">
                <a
                  *ngFor="let menuItem of menu.subMenu"
                  class="sub-menu__box__link"
                  routerLink="{{ menuItem.link }}"
                  routerLinkActive="active-link"
                >
                  <a>{{ menuItem.name }}</a>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="toolbar-container__buttons">
      <ime-btn-get-app [data]="buttonRequired"></ime-btn-get-app>
    </div>
    <div class="ham-menu" (click)="openMenu()">
      <img src="assets/icon/menu-icon.svg" alt="" />
    </div>
  </mat-toolbar>
</header>
