<div class="visa-card-container">
  <div class="visa-hero">
    <div class="visa-hero__content">
      <div class="visa-hero__figures">
        <div class="visa-hero__images">
          <img
            src="assets/images/visa-card-header.webp"
            alt=""
            class="visa-hero__images__main"
            data-aos="fade-out"
            data-aos-delay="500"
            alt=""
          />
          <!-- <img
            src="assets/images/activation-fee.webp"
            alt=""
            class="visa-hero__images__popup1"
            data-aos="fade-down-left"
            data-aos-delay="700"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            alt=""
          /> -->
        </div>
      </div>
      <div class="visa-hero__info">
        <div
          class="visa-hero__info__heading ime-heading-4 ime-hide-only-on-mobile"
        >
          Virtual & Physical <br /><span class="ime-color-red">IME </span>
          <span class="ime-color-blue">VISA</span> Card
        </div>
        <div
          class="visa-hero__info__heading ime-heading-4 ime-show-only-on-mobile"
        >
          <span class="ime-color-red">IME </span
          ><span class="ime-color-blue">VISA</span> Card<br />
          More ways to IME Pay
        </div>
        <div class="visa-hero__info__text ime-text-1">
          Packed with unlimited possibilites, IME VISA Card goes where you go,
          and brings your IME Pay balance along with you.
          <span class="ime-hide-only-on-mobile"
            >Pay, earn cashback and reward points right on your IME Pay Wallet
            Account.</span
          >
        </div>
      </div>
      <div class="visa-hero__button">
        <!-- <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app> -->
        <button mat-stroked-button class="get-app-btn mat-stroked-button mat-button-base" (click)="getPhysicalVisaCard()">Get Physical Visa Card</button>
      </div>
    </div>
  </div>

  <div class="container-grow-business">
    <div class="grow-business grow-business--alt content-container">
      <div class="grow-business__top">
        <img
          src="assets/icon/bell.png"
          alt=""
          class="grow-business__top__popup2 ime-hide-only-on-mobile"
          data-aos="fade-down-left"
        />
        <img
          src="assets/images/visa-card-popup.png"
          alt=""
          data-aos="zoom-in-right"
          class="grow-business__top__popup3"
          data-aos="fade-down-right"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />

        <img
          src="assets/images/atm-withdrawals.webp"
          alt=""
          class="grow-business__top__popup"
          data-aos="fade-left"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div class="grow-business__top__image">
          <div class="grow-business__top__image__img">
            <img
              src="assets/images/cashout.webp"
              loading="eager"
              alt=""
              data-aos="zoom-out"
              data-aos-duration="600"
              class="grow-business__top__image__img__main"
            />
          </div>
        </div>
      </div>
      <div class="grow-business__bottom">
        <div
          class="grow-business__bottom__left"
          data-aos="fade-right"
          data-aos-delay="300"
          data-aos-duration="600"
        >
          <div class="grow-business__bottom__left__heading ime-heading-1">
            <span class="ime-color-blue">Cash-out your</span> <br />
            wallet balance
          </div>
        </div>
        <div
          class="grow-business__bottom__right"
          data-aos="fade-left"
          data-aos-delay="500"
          data-aos-duration="600"
        >
          <div class="grow-business__bottom__right__text ime-text-1">
            It’s all in the app at your fingertips. From card freezing, to
            channel control, turn them on or off with a tap.
          </div>
          <button
            mat-stroked-button
            class="learn-more-btn"
            (mouseover)="
              imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
            "
            (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
            data-aos="fade-up"
            data-aos-delay="700"
            data-aos-duration="600"
            [routerLink]="['/', routerPath.PAY_ONLINE_IN_APPS]"
          >
            <div class="learn-more-btn-content">
              <div class="learn-more-btn-content__title">GET STARTED</div>
              <div class="learn-more-btn-content__img">
                <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="shop-virtually">
    <div class="shop-virtually__content">
      <div class="shop-virtually__left">
        <div class="shop-virtually__left__images">
          <img
            src="assets/images/virtualshoping.png"
            alt=""
            class="shop-virtually__left__images__main"
            data-aos="fade-in"
          />
          <img
            src="assets/images/virtual-card-payment.png"
            alt=""
            class="shop-virtually__left__images__popup1"
            data-aos="fade-right"
            data-aos-delay="700"
          />
          <img
            src="assets/images/visa-card-black.webp"
            alt=""
            class="shop-virtually__left__images__popup2"
          />
        </div>
      </div>
      <div class="shop-virtually__right">
        <div class="shop-virtually__right__container">
          <div
            class="shop-virtually__right__heading ime-heading-1"
            data-aos="zoom-in"
            data-aos-delay="300"
          >
            Shop <span class="ime-color-red">virtually</span>
          </div>
          <div
            class="shop-virtually__right__text ime-text-1"
            data-aos="zoom-in-up"
            data-aos-delay="500"
          >
            Pull up your VISA card details in the app to shop online everywhere
            even before your physical card arrives. Simply activate your virtual
            card in the app and enjoy shopping.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-grow-business">
    <div class="grow-business grow-business--alt content-container">
      <div class="grow-business__top">
        <img
          src="assets/images/virtual-card-payment.png"
          alt=""
          class="grow-business__top__popup grow-business__top__popup__touch"
          data-aos="zoom-in"
        />
        <div class="grow-business__top__image--small">
          <div class="grow-business__top__image__img--small">
            <img
              src="assets/images/touch-free-payment.webp"
              loading="eager"
              alt=""
              data-aos="zoom-out"
            />
          </div>
        </div>
      </div>
      <div class="grow-business__bottom">
        <div class="grow-business__bottom__left">
          <div
            class="grow-business__bottom__left__heading ime-heading-1"
            data-aos="fade-right"
            data-aos-delay="100"
            data-aos-duration="300"
            data-aos-easing="ease-in-out"
          >
            <span class="ime-color-red">Touch-free</span><br />payments
          </div>
        </div>
        <div class="grow-business__bottom__right">
          <div
            class="grow-business__bottom__right__text-2 ime-text-1"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            The IME VISA Card gives you another way to pay, and it’s always
            right in your wallet. No more inserting chips or swiping cards. Just
            tap your IME VISA card and go. <br /><br />IME Pay app gives you a
            simple, no-contact way to pay. Each business has a unique QR code so
            you just scan, pay, and go.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="in-control">
    <div class="in-control__container">
      <div class="in-control__container__left">
        <div class="in-control__container__left__img">
          <img
            src="assets/images/visa-card-dual.webp"
            alt=""
            class="in-control__container__left__img__main"
            data-aos="zoom-in"
            data-aos-delay="200"
          />
          <img
            src="assets/images/reset-pin-popup.png"
            alt=""
            class="in-control__container__left__img__popup1"
            data-aos="fade-right"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/lock-card-popup.png"
            alt=""
            class="in-control__container__left__img__popup2"
            data-aos="fade-left"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
      <div class="in-control__container__right">
        <div
          class="in-control__container__right__heading ime-heading-1 ime-hide-only-on-mobile"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Stay in control <br />
          of your<br />
          <span class="ime-color-red">card’s security</span>
        </div>
        <div
          class="in-control__container__right__heading ime-heading-1 ime-show-only-on-mobile"
          data-aos="fade-up"
          data-aos-delay="600"
        >
          Stay in control of your<br />
          <span class="ime-color-red">card’s security</span>
        </div>
        <div
          class="in-control__container__right__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="800"
        >
          Lost or misplaced your card? Disable it in the IME pay app. Found it?
          Enable it with a single tap. Want more privacy? Change your physical
          card PIN right from the app.
        </div>
      </div>
    </div>
  </div>

  <div class="notifications">
    <div class="notifications__container">
      <div class="notifications__container__left">
        <div
          class="notifications__container__left__heading ime-heading-1"
          data-aos="zoom-in"
          data-aos-delay="600"
        >
          Get instant payment <br />
          <span class="ime-color-red">notifications</span>
        </div>
        <div
          class="notifications__container__left__text ime-text-1"
          data-aos="zoom-in-up"
          data-aos-delay="800"
        >
          Feel confident your payment is successful with instant notifications.
          You’ll see how much you spent. You’ll also receive SMS for
          transactional and account activities.
        </div>
      </div>
      <div class="notifications__container__right">
        <div class="notifications__container__right__img">
          <img
            src="assets/images/notification-main.png"
            alt=""
            class="notifications__container__right__img__main"
            data-aos="zoom-in"
          />
          <img
            src="assets/images/notification-popup.png"
            alt=""
            class="notifications__container__right__img__popup1"
            data-aos="zoom-in-up"
            data-aos-delay="300"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/icon/bell-large-shadow.png"
            alt=""
            class="notifications__container__right__img__popup2"
            data-aos="fade-down-left"
            data-aos-delay="300"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="container-track-expense">
    <div class="container-track-expense__container">
      <div class="container-track-expense__left">
        <div
          class="container-track-expense__left__header ime-heading-1 ime-hide-only-on-mobile"
          data-aos="fade-in"
          data-aos-duration="600"
        >
          Track card <br /><span>expenses</span>
        </div>
        <div
          class="container-track-expense__left__header ime-heading-1 ime-show-only-on-mobile"
          data-aos="fade-in"
          data-aos-duration="600"
        >
          Track card <span>expenses</span>
        </div>
        <div
          class="container-track-expense__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="600"
        >
          Keep tabs on your IME Pay VISA card activity right in the app. Every
          purchase, payments, split, and reward.
        </div>
      </div>
      <div class="container-track-expense__right">
        <div class="container-track-expense__right__image">
          <img
            src="assets/images/trackexpenses.png"
            class="container-track-expense__right__image__visa-card"
            data-aos="fade-down-left"
            data-aos-delay="700"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
            alt=""
          />
          <img
            src="assets/images/recent-expense.png"
            class="container-track-expense__right__image__statement"
            data-aos="fade-up-left"
            data-aos-delay="800"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div class="container-manage-card">
    <div class="container-manage-card__container">
      <div class="container-manage-card__left">
        <div class="container-manage-card__left__image">
          <img
            src="assets/images/manage-cards.webp"
            class="container-manage-card__left__image__manage-cards"
            data-aos="fade-down-left"
            data-aos-delay="700"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
            alt=""
          />
          <img
            src="assets/images/channel-controls.webp"
            class="container-manage-card__left__image__channel-controls"
            data-aos="fade-up-left"
            data-aos-delay="800"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
            alt=""
          />
        </div>
      </div>
      <div class="container-manage-card__right">
        <div
          class="container-manage-card__right__header ime-heading-1"
          data-aos="fade-in"
          data-aos-duration="600"
        >
          Get a card<br />
          <span>you control</span>
        </div>
        <div
          class="container-manage-card__right__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="600"
        >
          It’s all in the app at your fingertips. From card freezing, to channel
          control, turn them on or off with a tap.
        </div>
      </div>
    </div>
  </div>

  <!-- instant notifications -->

  <div class="annual-fee">
    <div class="annual-fee__container">
      <div
        class="annual-fee__container__header"
        data-aos="fade-up"
        data-aos-delay="200"
      >
        No Annual Fees
      </div>
      <div class="annual-fee__inner-wrapper">
        <img
          src="assets/images/calender.webp"
          alt=""
          class="annual-fee__inner-wrapper__popup1"
          data-aos="fade-down-right"
          data-aos-delay="300"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <img
          src="assets/images/visa-card-dual.webp"
          alt=""
          class="annual-fee__inner-wrapper__popup2"
          data-aos="fade-up-left"
          data-aos-delay="500"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div class="annual-fee__inner-wrapper__top">
          <div class="annual-fee__inner-wrapper__top__text ime-text-4">
            Own a VISA Card both Physical and Virtual, free of cost and say
            goodbye to others.
          </div>
          <div class="annual-fee__inner-wrapper__top__learn-more">
            <a [href]="commision_link" target="_blank">
              <button
                mat-button
                class="annual-fee__inner-wrapper__top__button"
                color="primary"
              >
                Learn more
              </button>
            </a>
          </div>
        </div>
        <div class="annual-fee__inner-wrapper__bottom">
          <div class="annual-fee__inner-wrapper__bottom__rate">
            <div class="annual-fee__innter-wrapper__bottom__stats">
              <div class="annual-fee__inner-wrapper__bottom__fee ime-heading-1">
                Rs. 0/yr
              </div>
              <div class="annual-fee__inner-wrapper__bottom__type">
                Physical Card
              </div>
            </div>
            <div class="annual-fee__innter-wrapper__bottom__stats">
              <div class="annual-fee__inner-wrapper__bottom__fee ime-heading-1">
                Rs. 0/yr
              </div>
              <div class="annual-fee__inner-wrapper__bottom__type">
                Virtual Card
              </div>
            </div>
          </div>
          <div
            class="annual-fee__inner-wrapper__bottom__terms ime-hide-only-on-mobile"
          >
            *IME Pay does not charge for the subscription of both cards, or your
            IME Pay account. There is a fee for re-issue as per the Terms &
            Conditions.
          </div>
          <div
            class="annual-fee__inner-wrapper__bottom__terms ime-show-only-on-mobile"
          >
            *T&C Apply
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="do-more">
    <div class="do-more__container">
      <div
        class="do-more__heading ime-heading-1"
        data-aos="fade-up"
        data-aos-delay="600"
      >
        Extra benefits of IME VISA Card
      </div>
      <div class="do-more__cards">
        <div class="do-more__card" data-aos="zoom-out-up" data-aos-delay="200">
          <div class="do-more__card__images">
            <img
              src="assets/images/activation.webp"
              alt=""
              class="do-more__card__images__main"
            />
          </div>

          <div class="do-more__card__info">
            <div class="do-more__card__heading ime-heading-5">
              Instant & free activation
            </div>
            <div class="do-more__card__text ime-text-2">
              You don’t have to wait for your card to arrive after paying &
              filling long list of form—start using it from the moment it’s
              created.
            </div>
          </div>
        </div>

        <div class="do-more__card" data-aos="zoom-out-up" data-aos-delay="400">
          <div class="do-more__card__images">
            <img
              src="assets/images/secure-lock.webp"
              alt=""
              class="do-more__card__images__main"
            />
          </div>
          <div class="do-more__card__info">
            <div class="do-more__card__heading ime-heading-5">
              Advanced 3D Secure
            </div>
            <div class="do-more__card__text ime-text-2">
              Benefit from extra security against fraud, thanks to a two-factor
              authentication step that protects your online purchases.
            </div>
          </div>
        </div>

        <div class="do-more__card" data-aos="zoom-out-up" data-aos-delay="600">
          <div class="do-more__card__images">
            <img
              src="assets/images/wallet-free.webp"
              alt=""
              class="do-more__card__images__main"
            />
          </div>
          <div class="do-more__card__info">
            <div class="do-more__card__heading ime-heading-5">
              Leave wallet at home
            </div>
            <div class="do-more__card__text ime-text-2">
              No Internet? No phone? IME VISA card is secure, simple to use, and
              saves you the inconvenience of having to carry your wallet around.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="visa-cta">
    <div class="visa-cta__container">
      <div class="visa-cta__inner-wrapper">
        <img
          src="assets/images/visa-card-alt.webp"
          alt=""
          class="visa-cta__inner-wrapper__popup1"
          data-aos="popup-1"
          data-aos-delay="200"
        />
        <img
          src="assets/images/visa-card-black.webp"
          alt=""
          class="visa-cta__inner-wrapper__popup2"
          data-aos="popup-2"
          data-aos-delay="200"
        />
        <img
          src="assets/images/visa-card-alt.webp"
          alt=""
          class="visa-cta__inner-wrapper__popup3"
          data-aos="popup-3"
          data-aos-delay="200"
        />
        <img
          src="assets/images/cart-alt.png"
          alt=""
          class="visa-cta__inner-wrapper__popup4"
          data-aos="zoom-in-up"
          data-aos-delay="400"
        />

        <div
          class="visa-cta__header ime-heading-4"
          data-aos="zoom-in"
          data-aos-delay="600"
        >
          Apply For Virtual<br /><span class="ime-color-red">IME Pay </span>
          <span class="ime-color-blue">VISA</span>
          Card
        </div>
        <div class="visa-cta__text" data-aos="fade-up" data-aos-delay="800">
          Virtual IME VISA card is available for KYC verified users to apply
          using the latest version of the IME Pay app.
        </div>
        <div
          class="visa-cta__button"
          data-aos="flip-left"
          data-aos-delay="1000"
        >
          <ime-btn-get-app [data]="{ getApp: true }">GET APP</ime-btn-get-app>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</div>
