
<div class="GA-container">
  <div class="GA-container__content">
    
    <div class="GA-container__left">
      <div class="GA-container__left__heading ime-heading-2">
        Get started with IME Pay.
      </div>
      <div class="GA-container__left__title">
        Let’s start with your mobile number.
      </div>
      
      <div class="GA-container__left__form">
        <form class="form" [formGroup]="getStartedForm">
          <div class="form__elements">
            <mat-form-field
              appearance="outline"
              class="form__elements__country-field"
            >
              <mat-label>Country</mat-label>
              <mat-select formControlName="country" (click)="infoText()">
                <mat-option value="Nepal" selected>Nepal</mat-option>
              </mat-select>
              <mat-hint>Select country</mat-hint>
            </mat-form-field>
            <mat-form-field
              appearance="outline"
              class="form__elements__mobile-field"
            >
              <mat-label>Mobile Number</mat-label>
              <input
                matInput
                placeholder="Mobile Number"
                type="number"
                formControlName="ReceiverNo"
                autocomplete="off"
                (keydown)="limitInput($event)"
                min="1"
              />
              <mat-hint>Enter your mobile number</mat-hint>
              <mat-error *ngIf="phone.errors?.required">
                Mobile Number is required.
              </mat-error>
              <mat-error *ngIf="phone.errors?.msg">
                {{phone.errors?.msg}}
              </mat-error>
            </mat-form-field>
         
          </div>
          <button mat-raised-button class="send-link-btn" (click)="submit()">
            <div class="send-link-btn__content">Send Link for download</div>
          </button>
        </form>
        
      </div>
      <div class="GA-container__left__label">
        <div class="GA-container__left__label__icon">
          <img src="assets/icon/msg.svg" alt="" srcset="" />
        </div>
        <div class="GA-container__left__label__text">
          You will receive a message with a link for downloading the app in the
          above number.
        </div>
      </div>
    </div>
    <div class="GA-container__right">
      <div class="GA-container__right__img">
        <img
          src="assets/images/get-app-man.webp"
          class="get-app-img"
          alt=""
          srcset=""
        />
      </div>
    </div>
  </div>
  <div class="GA-container__bottom">
    <div
      class="GA-container__bottom__content"
      (click)="showHelpLine()"
    >
      <div class="GA-container__bottom__text">Contact Support</div>
      <div class="GA-container__bottom__icon">
        <img src="assets/icon/support-agent.svg" alt="" srcset="" />
      </div>
    </div>
  </div>
</div>
