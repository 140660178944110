<div class="container-pay-App">
  <div class="container-pay-online">
    <div class="container-pay-online__wrapper">
      <div class="container-pay-online__left">
        <div class="container-pay-online__left__img">
          <img
            src="assets/images/pay-online-in-apps.png"
            alt=""
            srcset=""
            data-aos="zoom-in-down"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
        <img
          src="assets/images/imepay-checkout.png"
          alt=""
          class="container-pay-online__left__popup-1"
          data-aos="fade-down-right"
          aTilt
          [tiltSettings]="tiltSettings"
        />
        <img
          src="assets/images/Cart.webp"
          alt=""
          class="container-pay-online__left__popup-2"
          data-aos="fade-up-left"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
      <div class="container-pay-online__right">
        <div
          class="container-pay-online__right__title ime-heading-1 ime-hide-only-on-mobile"
          data-aos="zoom-in-down"
          data-aos-delay="600"
        >
          <span class="ime-color-red"> Pay in apps </span> & <br />
          websites you love
        </div>
        <div
          class="container-pay-online__right__title ime-heading-1 ime-show-only-on-mobile"
          data-aos="zoom-in-down"
          data-aos-delay="600"
        >
          <span class="ime-color-red"> Pay in apps </span> & websites <br />you
          love
        </div>
        <div
          class="container-pay-online__right__label ime-text-1 ime-hide-only-on-mobile"
          data-aos="fade-up"
          data-aos-delay="1000"
        >
          Connect your IME Pay account to some of your favorite apps and
          websites. Then go through checkout with money you have in IME Pay.<br />
          Also use Virtual or Physical IME VISA cards to make payments in apps &
          websites where VISA cards are accepted.
        </div>
        <div
          class="container-pay-online__right__label ime-text-1 ime-show-only-on-mobile"
          data-aos="fade-up"
          data-aos-delay="1000"
        >
          Connect your IME Pay account to some of your favorite apps and
          websites. Then go through checkout with money you have in IME Pay.
          Also use Virtual or Physical IME VISA cards to make payments in apps &
          websites where VISA cards are accepted.
        </div>
        <div
          class="container-pay-online__right__button"
          data-aos="fade-up"
          data-aos-delay="1400"
        >
          <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
        </div>
      </div>
    </div>
  </div>

  <div class="container-trusted-brands">
    <div class="container-trusted-brands__left">
      <div
        class="container-trusted-brands__left__title ime-heading-1"
        data-aos="zoom-in-down"
      >
        Trusted <span class="ime-color-red">Brands</span>
      </div>
      <div
        class="container-trusted-brands__left__label ime-text-1"
        data-aos="fade-up"
        data-aos-delay="1600"
      >
        We have brought some of the biggest brands to partner up with IME Pay as
        their top online payment service.
      </div>
      <div class="container-trusted-brands__left__img">
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-daraz.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-foodmandu.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-sastodeal.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-bhoj.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-gyapu.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-kinmel.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-cg.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-cinemaghar.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-thulo.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-merolagani.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="1000"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/ime-partner-logo-neo.png"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="500"
          />
        </div>
        <div class="container-trusted-brands__left__img__logo">
          <img
            src="assets/logo/mero-share.webp"
            alt=""
            data-aos="zoom-in-up"
            data-aos-delay="500"
          />
        </div>
      </div>
    </div>
    <div class="container-trusted-brands__right ime-hide-only-on-mobile">
      <img
        src="assets/images/ime-partner-image-bhoj.png"
        alt=""
        class="container-trusted-brands__right__image-2"
        #brandsDiv
      />
      <img
        src="assets/images/ime-partner-image-daraz.webp"
        alt=""
        class="container-trusted-brands__right__image-1"
        srcset=""
        aTilt
        [tiltSettings]="tiltSettings"
        data-aos="fade-left"
        data-aos-delay="600"
      />
    </div>
  </div>

  <div class="container-simple-safe">
    <div class="container-simple-safe__box">
      <div class="container-simple-safe__box__title" data-aos="zoom-in-down">
        Simple & safe
      </div>
      <div class="container-simple-safe__box__cards">
        <div
          class="card"
          data-aos="fade-right"
          data-aos-delay="300"
          data-aos-duration="600"
        >
          <div class="card__image">
            <img
              src="assets/images/dancing.webp"
              class="card__image__main img-dancing"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5 ime-hide-only-on-mobile">
              Pay now, request later
            </div>
            <div class="card__heading ime-heading-5 ime-show-only-on-mobile">
              Pay now, <br/>request later
            </div>
            <div class="card__text ime-text-2">
              Pay with IME Pay on behalf of your friends or family & request the
              bill with just a few taps.
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="fade-right"
          data-aos-delay="900"
          data-aos-duration="600"
        >
          <div class="card__image">
            <img
              src="assets/images/recent-transactions-1.webp"
              class="card__image__main"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">
              Track your transactions
            </div>
            <div class="card__text ime-text-2">
              We’ll keep track of what you paid, when, and where. Get track of
              everything from the app.
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="fade-right"
          data-aos-delay="1400"
          data-aos-duration="600"
        >
          <div class="card__image">
            <img
              src="assets/images/encrypted.webp"
              class="card__image__main"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Safe transactions</div>
            <div class="card__text ime-text-2">
              Encryption technology helps protect your card and bank data, IME
              Pay balance are backed by our partner banks.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-pay-like-pro">
    <div class="container-pay-like-pro__content">
      <div class="container-pay-like-pro__content__left">
        <div class="container-pay-like-pro__content__left__images">
          <img
            src="assets/images/checkout-in-app.webp"
            alt=""
            srcset=""
            class="img-front"
            data-aos="fade-up-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="800"
            aTilt
            [tiltSettings]="tiltSettings"
          />
          <img
            src="assets/images/checkout-in-app-2.webp"
            alt=""
            srcset=""
            class="img-back"
            data-aos="fade-up-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            aTilt
            [tiltSettings]="tiltSettings"
          />
          <img
            src="assets/images/cheers-logo.webp"
            alt=""
            srcset=""
            class="icon-cheers"
            data-aos="fade-up-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            aTilt
            [tiltSettings]="tiltSettings"
          />
          <img
            src="assets/images/sasto-logo.webp"
            alt=""
            srcset=""
            class="icon-sasto"
            data-aos="fade-up-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
      </div>
      <div class="container-pay-like-pro__content__right">
        <div
          class="container-pay-like-pro__content__right__title ime-heading-2"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
        >
          Shop like a <span class="ime-color-red">pro</span>
        </div>
        <div
          class="container-pay-like-pro__content__right__sub-title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
        >
          Use your IME Pay account or Virtual & Physical IME VISA card to shop
          on thousands of apps and websites. IME Pay is already accepted by 3
          million plus merchants (Fonepay, Unionpay, SCT, VISA & IME Pay) with
          more joining all the time.
        </div>

      </div>
    </div>
  </div>

  <div class="container-imePay-for-business">
    <div class="container-imePay-for-business__wrapper">
      <div class="container-imePay-for-business__left">
        <div
          class="container-imePay-for-business__left__title ime-heading-2"
          data-aos="zoom-in-down"
          data-aos-duration="600"
        >
          Get IME Pay for your <br /><span class="ime-color-red">business</span>
        </div>
        <div
          class="container-imePay-for-business__left__label ime-text-2"
          data-aos="fade-right"
          data-aos-delay="500"
          data-aos-duration="600"
        >
          Accept IME Pay at checkout in your app or mobile site, and tap into
          our social community of over 3 million plus users.
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="fade-up"
          data-aos-delay="1600"
          data-aos-duration="600"
          [routerLink]="['/',routerPath.COMING_SOON]"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">Learn more</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>

      <div class="container-imePay-for-business__right">
        <img
          src="assets/images/pay-for-business.webp"
          alt=""
          class="container-imePay-for-business__right__img-1"
          data-aos="fade-up"
          data-aos-delay="400"
          data-aos-duration="600"
        />
        <img
          src="assets/images/getimepayforbusiness.png"
          class="container-imePay-for-business__right__img-2"
          alt=""
          data-aos="fade-up"
          data-aos-delay="900"
          data-aos-duration="600"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <img
          src="assets/images/cart-alt.png"
          class="icon-cart-business"
          alt=""
          data-aos="fade-up"
          data-aos-delay="900"
          data-aos-duration="600"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
    </div>
  </div>

  <!-- safter-way to pay -->
  <div class="safer-way__service">
    <div
      class="safer-way__service__contents safer-way__service content-container"
    >
      <div class="safer-way__service__contents__right">
        <div class="safer-way__service__contents__right__images">
          <img
            src="assets/images/pcidss.webp"
            class="safer-way__service__contents__right__images__safety"
            alt=""
            data-aos="zoom-in-down"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
          />
          <div class="safer-way__service__contents__right__images__container">
            <img
              src="assets/images/protected.webp"
              class="safer-way__service__contents__right__images__lock"
              alt=""
              data-aos="fade-down-right"
              data-aos-delay="300"
              data-aos-duration="600"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
      <div class="safer-way__service__contents__left">
        <div
          class="safer-way__service__contents__left__heading ime-heading-1 ime-hide-only-on-mobile"
          data-aos="zoom-in-down"
          data-aos-delay="500"
          data-aos-duration="600"
        >
          <span class="ime-color-red">PCIDSS</span><br />
          certified
        </div>
        <div
          class="safer-way__service__contents__left__heading ime-heading-1 ime-show-only-on-mobile"
          data-aos="zoom-in-down"
          data-aos-delay="500"
          data-aos-duration="600"
        >
          <span class="ime-color-red">PCIDSS</span> certified
        </div>
        <div
          class="safer-way__service__contents__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="1000"
          data-aos-duration="600"
        >
          The Payment Card Industry Data Security Standard (PCI DSS) is a set of
          security standards designed to ensure IME Pay accepts, processes,
          stores or transmits VISA card information in a secure environment.
        </div>
        <button
        mat-stroked-button
        class="learn-more-btn certification-btn"
        (mouseover)="
          imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
        "
        (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
        (click)="externalLink('https://www.sisainfosec.com/certificate.php?number=71441091319541698652&type=pcidss')"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="1600"
      >
        <div class="learn-more-btn-content">
          <div class="learn-more-btn-content__title">
            see our certification
          </div>
          <div class="learn-more-btn-content__img">
            <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
          </div>
        </div>
      </button>
      </div>
    </div>
  </div>

  <!-- pay-smarter -->
  <div class="container-pay-smarter">
    <div class="container-banner">
      <div class="container-pay-smarter__wrapper">
        <img
          src="assets/images/cart-alt.png"
          alt=""
          class="container-pay-smarter__wrapper__gift-image"
          data-aos="fade-down-right"
          data-aos-delay="1500"
          data-aos-duration="600"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div class="container-banner__left">
          <img
            src="assets/images/card-dual.webp"
            class="container-banner__left__visa-card"
            alt=""
            data-aos="fade-right"
            data-aos-delay="500"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
        <div class="container-banner__middle">
          <div
            class="container-banner__middle__title ime-hide-only-on-mobile"
            data-aos="flip-left"
          >
            <span class="ime-color-red">Start paying </span>with<br />
            IME Pay today
          </div>
          <div
            class="container-banner__middle__title ime-show-only-on-mobile"
            data-aos="flip-left"
          >
            <span class="ime-color-red">Start paying </span><br />
            with IME Pay
          </div>
          <div
            class="container-banner__middle__button"
            data-aos="zoom-in-down"
            data-aos-delay="1600"
            data-aos-duration="600"
          >
            <ime-btn-get-app [data]="{ getApp: true }">GET APP</ime-btn-get-app>
          </div>
        </div>
        <div class="container-banner__right">
          <img
            src="/assets/images/cta-app-online.webp"
            class="container-banner__right__qr-scan"
            alt=""
            data-aos="fade-left"
            data-aos-delay="1000"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</div>
