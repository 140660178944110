import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ErrorHandler, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingPageModule } from './landing-page/landing-page.module';
import { CustomErrorHandler } from './core/_errorHandler/CustomErrorHandler';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SmsInterceptor } from './core/_interceptors/sms.interceptor';
import { RouterModule } from '@angular/router';
import { MaterialsModule } from './materials/materials.module';


@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    LandingPageModule,
    SharedModule,
    CoreModule,
    RouterModule,
    MaterialsModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SmsInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useClass: CustomErrorHandler
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
