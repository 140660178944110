import { Component, OnInit, ViewChild } from '@angular/core';
import {  MatSidenav } from "@angular/material/sidenav";
import { ROUTER_BASE_PATH } from '../../constants/router-path';
@Component({
  selector: 'ime-side-nav-mobile',
  templateUrl: './side-nav-mobile.component.html',
  styleUrls: ['./side-nav-mobile.component.scss']
})
export class SideNavMobileComponent implements OnInit {
  @ViewChild('sideNav', {static: true}) sidenav: MatSidenav;

  showHeader = true;
  menuItems = [
    {
      menuItem: "Send & Receive",
      class: 'menu__sendReceive',
      subMenu: [
        {
          name: "How it works",
          link: ROUTER_BASE_PATH.HOW_IT_WORKS,
        },
        {
          name: "Remittance",
          link: ROUTER_BASE_PATH.REMITTANCE,
        },
        {
          name: "Wallet Transfer",
          link: ROUTER_BASE_PATH.WALLET_TRANSFER,
        },
        {
          name: "Bank & Transfers",
          link: ROUTER_BASE_PATH.BANK_TRANSFER,
        },
      ],
    },
    {
      menuItem: "Pay with IME Pay",
      class: 'menu__withImePay',
      subMenu: [
        {
          name: "Ways to pay",
          link: ROUTER_BASE_PATH.WAY_TO_PAY,
        },
        {
          name: "Pay For IME Pay Services",
          link: ROUTER_BASE_PATH.PAY_SERVICE,
        },
        {
          name: "Pay in Apps and Online",
          link: ROUTER_BASE_PATH.PAY_ONLINE_IN_APPS,
        },
        {
          name: "Pay in Stores",
          link: ROUTER_BASE_PATH.PAY_IN_STORE,
        },
        {
          name: "IME Pay Visa Card",
          link: ROUTER_BASE_PATH.PAY_THROUGH_CARD,
        },
      ],
    },

  ];
  constructor() { }

  ngOnInit(): void {

  }

  closeMenu(){
    this.showHeader = !this.showHeader;
    this.sidenav.close();
  }
  hideHeader(eventData){
    this.showHeader = !this.showHeader;
  }
}
