<div class="footer-container ime-hide-only-on-mobile">
  <hr />
  <div class="footer-container__contents content-container">
    <div class="container_links">
      <div
        class="sendReceive"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="500"
      >
        <span class="sendReceive__title"> Send & Receive </span>
        <div class="sendReceive__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.HOW_IT_WORKS"
            routerLinkActive="active-link"
          >
            How it works
          </a>
          <div
            class="routeLink"
            [routerLink]="'../' + routerPath.REMITTANCE"
            routerLinkActive="active-link"
          >
            Remittance
          </div>
          <div
            class="routeLink"
            [routerLink]="'../' + routerPath.WALLET_TRANSFER"
            routerLinkActive="active-link"
          >
            Wallet Transfer
          </div>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.BANK_TRANSFER"
            routerLinkActive="active-link"
          >
            Bank & Transfers
          </a>
        </div>
      </div>
      <div
        class="payWithImePay"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="700"
      >
        <span class="payWithImePay__title"> Pay with IME Pay </span>
        <div class="payWithImePay__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.WAY_TO_PAY"
            routerLinkActive="active-link"
          >
            Ways to Pay
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PAY_SERVICE"
            routerLinkActive="active-link"
          >
            Pay For IME Pay Services
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PAY_ONLINE_IN_APPS"
            routerLinkActive="active-link"
          >
            Pay in Apps and Online
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PAY_IN_STORE"
            routerLinkActive="active-link"
          >
            Pay in Stores
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PAY_THROUGH_CARD"
            routerLinkActive="active-link"
          >
            IME Pay Visa Card
          </a>
        </div>
      </div>
      <div
        class="payForBusiness"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="900"
      >
        <span class="payForBusiness__title"> IME Pay for Business </span>
        <div class="payForBusiness__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            Accept IME Pay payments
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            Register your Business
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            Accept IME Pay in apps & online
          </a>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            Accept IME Pay in Stores
          </a>
        </div>
      </div>
      <div
        class="qrSection"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1000"
      >
        <div class="qr-code">
          <img src="assets/images/get-app-scan-qr.webp" />
        </div>
        <span> Scan to get the app. </span>
      </div>
      <div
        class="company"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1100"
      >
        <span class="company__title">Company</span>
        <div class="company__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.ABOUT_US"
            routerLinkActive="active-link"
            >About Us</a
          >
          <div
            class="routeLink"
            (click)="goToLink(ourAgentList)"
          >
          Our Agents
          </div>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.ACCESSIBILITY_STATEMENT"
            routerLinkActive="active-link"
            >Accessibility Statement</a
          >
          <a
            class="routeLink"
            routerLinkActive="active-link"
            (click)="
              goToLink(
                'https://www.linkedin.com/company/imepay-official/jobs/'
              )
            "
            >Career</a
          >
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.TERMS"
            routerLinkActive="active-link"
            >Terms & Conditions</a
          >
          <a
          class="routeLink"
          [routerLink]="'../' + routerPath.SAFETY_AND_COMPLIANCE"
          routerLinkActive="active-link"
          >Safety & Compliance</a
        >
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.PRIVACY_POLICY"
            routerLinkActive="active-link"
            >Privacy</a
          >

          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.GRIEVANCES"
            routerLinkActive="active-link"
          >Grievances</a
          >
          <a
          class="routeLink"
          [routerLink]="'../' + routerPath.ISMS_POLICY"
          routerLinkActive="active-link"
        >ISMS Policy</a
        >
        </div>
      </div>
      <div
        class="resource"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1300"
      >
        <span class="resource__title">Resources</span>
        <div class="resource__links">
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            How to ?
          </a>
          <div
            class="routeLink"
            (click)="goToLink(commissionAndCharges)"
          >
            Charges & Commission
          </div>
          <a
            class="routeLink"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            Help & Support
          </a>
          <div
            class="routeLink"
            (click)="goToLink('https://blog.imepay.com.np/')"
          >
            Blog
          </div>
          <div
          class="routeLink"
          (click)="
            goToLink(
              'http://developer.imepay.com.np/'
            )
          "
        >
        Developers
        </div>
        </div>
      </div>
      <div
        class="contact"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1500"
      >
        <span class="contact__title">Contact</span>
        <div class="contact__links">
          <div class="contact__text">Toll Free No. : 16600161616</div>
          <div class="contact__text">01 4217601 / 4217600</div>
          <div class="contact__text">
            <a class="contact__text" href="mailto:info@imepay.com.np">
              info@imepay.com.np
            </a>
          </div>
          <div class="contact__text">Whastapp : +977 9803556655</div>
        </div>
      </div>
      <div
        class="copyRight"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1500"
      >
        <img
          src="assets/images/ime-digital-copyRight.svg"
          alt="logo of ime digital"
        />
        <span>Copyright © 2021 IME Digital Solutions. All Rights Reserved</span>
      </div>
    </div>
    <div class="footer">
      <div class="footer__left">
        <div class="logo">
          <img src="assets/logo/ime-main.svg" alt="logo of ime group" />
        </div>
        <div class="social--links">
          <a
            href="https://www.facebook.com/imepay"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[0]"
            ></ime-json-animator>
          </a>
          <a
            href="https://www.youtube.com/channel/UCqg5Kde1gJ13Tr2GsqE9tNg"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[1]"
            ></ime-json-animator>
          </a>
          <a
            href="https://www.instagram.com/imepay_official"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[2]"
            ></ime-json-animator>
          </a>
          <a
            href="https://www.linkedin.com/company/imepay-official/"
            target="_blank"
            class="social-icons"
          >
            <ime-json-animator
              [options]="animationIconOption[3]"
            ></ime-json-animator>
          </a>
        </div>
      </div>
      <div class="footer__middle">
        <a
          href="https://www.sisainfosec.com/certificate.php?number=31005913646142466455&type=pcidss"
          target="_blank"
          rel="sisa certified"
          matTooltip="Sisa certified. Click here to view"
          matTooltipPosition="above"
        >
          <img
            [src]="sisaImgUrl"
            alt=""
            class="footer__middle__img-1"
            (mouseenter)="sisaImgUrl = 'assets/images/sisa-hover.png'"
            (mouseleave)="sisaImgUrl = 'assets/images/sisa.png'"
          />
        </a>
        <a
        href="https://connect2.amtivo.com/cert/amtivocert10001.asp?c=626442&v=65glgg396r&e=60018"
        target="_blank"
        rel="Amtivo certified"
      >
        <img
          [src]="amtivoImgUrl"
          matTooltip="Amtivo certified. Click here to view"
          alt="Amtivo certified"
          class="footer__middle__img-2"
        />
      </a>
      </div>

      <div class="footer__right">
        1- IME Pay uses advanced security features to protect millions of
        people’s data and payments each year. From two factor verification to
        data encryption, we take the safety of your money and information
        seriously. <br />2- IME Pay is a financial platform, not a bank. Banking
        services and visa cards are issued by IME Pay's partner banks.
      </div>
    </div>
  </div>
</div>

<div class="footer-container-mb ime-show-only-on-mobile">
  <div class="footer-container-mb__contents contents content-container">
    <div class="contents__menus">
      <mat-accordion>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState1 = true"
          (closed)="panelOpenState1 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">Send & Receive</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState1
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.HOW_IT_WORKS"
          >
            How it works
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.REMITTANCE"
          >
            Remittance
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.WALLET_TRANSFER"
          >
            Wallet Transfer
          </div>

          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.BANK_TRANSFER"
          >
            Bank & Transfers
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState2 = true"
          (closed)="panelOpenState2 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">Pay with IME Pay</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState2
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.WAY_TO_PAY"
          >
            Ways to Pay
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PAY_SERVICE"
          >
            Pay for IME Pay Services
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PAY_ONLINE_IN_APPS"
          >
            Pay in Apps & Online
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PAY_IN_STORE"
          >
            Pay in Stores
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PAY_THROUGH_CARD"
          >
            IME Pay VISA Card
          </div>
        </mat-expansion-panel>
        <mat-expansion-panel hideToggle disabled>
          <mat-expansion-panel-header>
            <div class="menus__title">
              <a
                routerLinkActive="active-link"
                [routerLink]="'../' + routerPath.COMING_SOON"
                rel="for business"
              >
                IME Pay for Business</a
              >
            </div>
          </mat-expansion-panel-header>
        </mat-expansion-panel>

        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState3 = true"
          (closed)="panelOpenState3 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">Company</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState3
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.ABOUT_US"
          >
            About
          </div>
          <div
          class="menus__subtitle"
          routerLinkActive="active-link"
          (click)="
            goToLink(ourAgentList)
          "
          >Our Agents</div
        >
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.ACCESSIBILITY_STATEMENT"
          >
            Accessibility Statement
          </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            (click)="
              goToLink(
                'https://www.linkedin.com/company/imepay-official/jobs'
              )
            "
            >Career</div
          >
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.TERMS"
          >
            Terms & Conditions
          </div>
          <div
          class="menus__subtitle"
          routerLinkActive="active-link"
          [routerLink]="'../' + routerPath.SAFETY_AND_COMPLIANCE"
        >
          Safety & Compliance
        </div>
          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.PRIVACY_POLICY"
          >
            Privacy
          </div>

          <div
            class="menus__subtitle"
            routerLinkActive="active-link"
            [routerLink]="'../' + routerPath.GRIEVANCES"
          >
            Grievances
          </div>
          <div
          class="menus__subtitle"
          routerLinkActive="active-link"
          [routerLink]="'../' + routerPath.ISMS_POLICY"
        >
          ISMS POLICY
        </div>


        </mat-expansion-panel>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState4 = true"
          (closed)="panelOpenState4 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">Resources</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState4
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div
            class="menus__subtitle"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            How to?
          </div>
          <div
            class="menus__subtitle"
            (click)="goToLink(commissionAndCharges)"
          >
            Charges & Commission
          </div>
          <div
            class="menus__subtitle"
            [routerLink]="'../' + routerPath.COMING_SOON"
            routerLinkActive="active-link"
          >
            Help & Support
          </div>
          <div
            class="menus__subtitle"
            (click)="goToLink('https://blog.imepay.com.np/')"
          >
            Blog
          </div>
          <div
          class="menus__subtitle"
          (click)="goToLink('http://developer.imepay.com.np/')"
        >
          Developers
        </div>
        </mat-expansion-panel>
        <mat-expansion-panel
          hideToggle
          (opened)="panelOpenState5 = true"
          (closed)="panelOpenState5 = false"
        >
          <mat-expansion-panel-header>
            <div class="menus">
              <div class="menus__title">Contact</div>
              <div class="icon-expand">
                <img
                  src="assets/icon/expand-icon.svg"
                  alt=""
                  [ngClass]="
                    panelOpenState5
                      ? 'accordion-icon accordion-icon__rotate'
                      : 'accordion-icon'
                  "
                />
              </div>
            </div>
          </mat-expansion-panel-header>
          <div class="menus__subtitle">Toll Free No. : 16600151515</div>
          <div class="menus__subtitle">01 4217601 / 4217600</div>
          <div class="menus__subtitle">info@imepay.com.np</div>
          <div class="menus__subtitle">Whatsapp:: +977 9803556655</div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>
    <div class="contents__social-icons">
      <div class="social--links">
        <a
          href="https://www.facebook.com/imepay"
          target="_blank"
          class="social-icons"
        >
          <ime-json-animator
            [options]="animationIconOption[0]"
          ></ime-json-animator>
        </a>
        <a
          href="https://www.youtube.com/channel/UCqg5Kde1gJ13Tr2GsqE9tNg"
          target="_blank"
          class="social-icons"
        >
          <ime-json-animator
            [options]="animationIconOption[1]"
          ></ime-json-animator>
        </a>
        <a
          href="https://www.instagram.com/imepay_official"
          target="_blank"
          class="social-icons"
        >
          <ime-json-animator
            [options]="animationIconOption[2]"
          ></ime-json-animator>
        </a>
        <a
          href="https://www.linkedin.com/company/imepay-official/"
          target="_blank"
          class="social-icons"
        >
          <ime-json-animator
            [options]="animationIconOption[3]"
          ></ime-json-animator>
        </a>
      </div>
    </div>
    <div class="contents__copy-right">
      <div
        class="copyRight"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-duration="1500"
      >
        <img
          src="assets/images/ime-digital-copyRight.svg"
          alt="logo of ime digital"
        />
        <span>Copyright © 2021 IME Digital Solutions. All Rights Reserved</span>
      </div>
    </div>
  </div>
</div>
