import { MaterialsModule } from './../materials/materials.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PagenotfoundComponent } from './components/page-not-found/page-not-found.component';
import { YoutubeComponent } from './components/youtube/youtube.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './layout/footer/footer.component';
import { HeaderComponent } from './layout/header/header.component';
import { SafeUrlPipe } from './pipes/safeUrl/safe-url.pipe';
import { AdsBannerComponent } from './pages/add-banner/ads-banner.component';
import { ClipboardModule } from '@angular/cdk/clipboard';

import { LottieModule } from 'ngx-lottie';
import { ImeJsonAnimatorComponent } from './components/ime-json-animator/ime-json-animator.component';
import { BtnGetAppComponent } from './components/buttons/btn-get-app/btn-get-app.component';
import { ContactSupportComponent } from './components/contact-support/contact-support.component';
import { SideNavMobileComponent } from './layout/side-nav-mobile/side-nav-mobile.component';
import { ComingSoonComponent } from './components/coming-soon/coming-soon.component';
import { GetAppMessageComponent } from './components/get-app-message/get-app-message.component';
import { CdnPipe } from './pipes/cdn/cdn.pipe';

export function playerFactory() {
  return import('lottie-web');
}

@NgModule({
  declarations: [
    PagenotfoundComponent,
    YoutubeComponent,
    HeaderComponent,
    FooterComponent,
    SafeUrlPipe,
    AdsBannerComponent,
    ImeJsonAnimatorComponent,
    BtnGetAppComponent,
    ContactSupportComponent,
    SideNavMobileComponent,
    ComingSoonComponent,
    GetAppMessageComponent,
    CdnPipe
  ],
  imports: [
    CommonModule,
    MaterialsModule,
    RouterModule,
    ClipboardModule,
    LottieModule.forRoot({ player: playerFactory })

  ],
  exports:[
    HeaderComponent,
    FooterComponent,
    AdsBannerComponent,
    LottieModule,
    ImeJsonAnimatorComponent,
    BtnGetAppComponent,
    ContactSupportComponent,
    SideNavMobileComponent,
    CdnPipe
  ],
})
export class SharedModule { }
