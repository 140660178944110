<div class="WT-container">
  <section class="hero-section-container">
    <div class="container-wallet-transfer">
      <div class="hero-section content-container">
        <div class="hero-section__get-app">
          <div
            class="hero-section__get-app__header ime-heading-1"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-easing="ease-in-out-quart"
            data-aos-delay="100"
          >
            IME Pay to <br /><span class="ime-color-red">friends & family</span>
          </div>
          <div
            class="hero-section__get-app__desc ime-text-1--alt"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-easing="ease-in-out-quart"
            data-aos-delay="300"
          >
            Send and request money any way you want. Send payment requests or
            flash QR codes, and tell friends to “IME Pay me!”
          </div>
          <div
            class="hero-section__get-app__button"
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-easing="ease-in-out-quart"
            data-aos-delay="500"
          >
            <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
          </div>
        </div>

        <div class="hero-section__right">
          <div class="hero-section__right__banner">
            <div class="hero-section__right__banner__img">
              <div>
                <img
                  src="assets/images/imepay-to-friends-banner.webp"
                  class="hero-section__right__banner__mainImg"
                  alt=""
                  aTilt
                  [tiltSettings]="tiltSettingsImage"
                />
              </div>
              <img
                src="assets/icon/rocket.webp"
                class="hero-section__right__banner__icon-rocket"
                alt=""
              />
              <div class="hero-section__right__banner__bg-box"></div>
              <img
                src="assets/images/lunch-money-sent.png"
                class="hero-section__right__banner__icon-lunch-money-sent"
                alt=""
                aTilt
                [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>


  <section class="pay-to-friend-container">
    <div class="pay-to-friend content-container">
      <div class="pay-to-friend__left">
        <div
          class="pay-to-friend__left__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-easing="ease-out-cubic"
        >
          <span class="ime-color-red"> Pay </span> friends <br />& family
        </div>

        <div
          class="pay-to-friend__left__desc ime-text-1"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="300"
          data-aos-easing="ease-out-cubic"
        >
          Send money with IME Pay and express yourself with a fun message,
          inside joke or emojis.
        </div>
      </div>
      <div class="pay-to-friend__middle">
        <div class="pay-to-friend__middle__img">
          <img
            src="assets/images/pay-to-friends-banner.webp"
            class="pay-to-friend__middle__img__main-img"
            alt=""
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="100"
            aTilt
            [tiltSettings]="tiltSettingsImage"
          />
          <img
            src="assets/images/recent-transactions.png"
            class="pay-to-friend__middle__icon-recent-transaction"
            alt=""
            data-aos="fade-up-left"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/search-contact.png"
            class="pay-to-friend__middle__icon-search-contact"
            alt=""
            data-aos="fade-up-right"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/icon/clock.png"
            class="pay-to-friend__middle__icon-clock"
            alt=""
            data-aos="fade-up-right"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
      <div class="pay-to-friend__right">
        <div class="pay-to-friend__right__how-to">
          <div
            class="pay-to-friend__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="300"
          >
            Find your Friends
          </div>
          <div
            class="pay-to-friend__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="500"
          >
            using their mobile number, QR codes or unique username found in
            their profile.
          </div>
          <div
            class="pay-to-friend__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="800"
          >
            Express yourself
          </div>

          <div
            class="pay-to-friend__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1000"
          >
            in each payment. Share a reason, emojis or an inside joke. It’s up
            to you.
          </div>

          <div
            class="pay-to-friend__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1300"
          >
            Send
          </div>
          <div
            class="pay-to-friend__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1500"
          >
            payments to your friends & family & get the fun of sending right
            into your notifications.
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="container-request-money content-container">
    <div class="container-request-money__left">
      <div class="container-request-money__left__img">
        <img
          src="assets/images/request-money.png"
          class="container-request-money__left__img__main-img"
          alt=""
          srcset=""
          data-aos="fade-up"
          data-aos-duration="700"
          data-aos-easing="ease-in-sine"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <img
          src="assets/icon/bell-large-shadow.png"
          class="container-request-money__left__img__icon-bell ime-hide-only-on-mobile"
          alt=""
          srcset=""
          data-aos="fade-up-right"
          data-aos-duration="700"
          data-aos-easing="ease-in-sine"
          data-aos-delay="300"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
    </div>
    <div class="container-request-money__right">
      <span
        class="container-request-money__right__title ime-heading-1"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-easing="ease-in-sine"
      >
        Get paid <br /><span class="ime-color-red">from anyone</span>
      </span>
      <span
        class="container-request-money__right__label ime-text-1"
        data-aos="fade-up"
        data-aos-duration="900"
        data-aos-easing="ease-in-sine"
        data-aos-delay="300"
      >
        From restaurant bills to rent, split expenses with anyone. Let us take
        the trouble of asking money from your friends. Just request in the app
        and we’ll handle the rest for you. Add personality to your request
        message with text & custom emojis.
      </span>
    </div>
  </div>

  <div class="container-invite-friend content-container">
    <div class="container-invite-friend__left">
      <span
        class="invite-earn-text__title ime-heading-1"
        data-aos="fade-up"
        data-aos-duration="800"
        data-aos-easing="ease-in-sine"
      >
        Invite your <br /><span class="ime-color-red">friends</span>
      </span>
      <span
        class="invite-earn-text__label ime-text-1"
        data-aos="fade-up"
        data-aos-duration="900"
        data-aos-easing="ease-in-sine"
        data-aos-delay="300"
      >
        Tired of asking your friends to pay you back? Good news - there are a
        few ways that you can invite your friends to use IME Pay!
      </span>
    </div>
    <div class="container-invite-friend__right">
      <div class="container-invite-friend__right__img">
        <img
          src="assets/images/invite-get-reward.png"
          class="container-invite-friend__right__img__main-img"
          alt=""
          data-aos="fade-up"
          data-aos-easing="ease-in-quart"
          data-aos-duration="800"
          data-aos-delay="100"
          aTilt
          [tiltSettings]="tiltSettingsImage"
        />
        <img
          src="assets/images/Gift_Left.webp"
          class="container-invite-friend__right__img__icon-gift"
          alt=""
          data-aos="fade-up-right"
          data-aos-easing="ease-in-quart"
          data-aos-duration="800"
          data-aos-delay="500"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <img
          src="assets/icon/gold-coin.png"
          class="container-invite-friend__right__img__gold-coin"
          alt=""
          data-aos="fade-up-left"
          data-aos-easing="ease-in-quart"
          data-aos-duration="800"
          data-aos-delay="500"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
    </div>
  </div>

  <div class="container-hidden-fee content-container">
    <div class="container-hidden-fee__background">
      <div class="container-hidden-fee__box__title"
      data-aos="custom-zoom-in"
      data-aos-delay="800"
      data-aos-duration="600"
      data-aos-anchor-placement="top-bottom"
      >
        No Hidden fees
      </div>
      <div class="container-hidden-fee__box">
        <img
          src="assets/icon/pig-no-fee.png"
          class="icon__pig"
          alt=""
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div
          class="container-hidden-fee__box__subtitle ime-text-4"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="1300"
          data-aos-anchor-placement="top-bottom"
        >
          It costs nothing to send or receive money using what’s in your IME Pay
          account or any bank account.
        </div>
        <div
          class="container-hidden-fee__box__learn-more-link"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="1400"
          data-aos-anchor-placement="top-bottom"
        >
          <a [href]="commision_link" target="_blank">LEARN MORE</a>
        </div>
        <div class="rupees-section">
          <div
            class="rupees-section__wallet-transfer"
            data-aos="zoom-in-left"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="1000"
            data-aos-delay="1500"
            data-aos-anchor-placement="top-bottom"
          >
            <span class="charge-rs">Rs. 0</span>
            <span class="charge-label">Wallet Transfer</span>
          </div>
          <div
            class="rupees-section__remmittance"
            data-aos="zoom-in-left"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="1200"
            data-aos-delay="1600"
            data-aos-anchor-placement="top-bottom"
          >
            <span class="charge-rs">Rs. 0</span>
            <span class="charge-label">Request money</span>
          </div>
        </div>
        <span
          class="container-hidden-fee__box__desc"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
          data-aos-delay="1800"
          data-aos-anchor-placement="top-bottom"
        >
          *IME Pay does not charge any fee for sending money from a linked bank
          account, VISA card, or your IME Pay account. There is a fee for sending
          money using a linked credit card.
        </span>
        <span class="container-hidden-fee__box__bottom-text-mobile"
          >*T&C Apply</span
        >
        <img
          src="assets/images/Hand.webp"
          class="icon__hand"
          alt=""
          data-aos="fade-left"
          data-aos-easing="ease-out-sine"
          data-aos-duration="600"
          data-aos-delay="900"
          data-aos-anchor-placement="top-bottom"
        />
        <img
          src="assets/images/plane.webp"
          class="icon__plane"
          alt=""
          data-aos="fade-down"
          data-aos-delay="1100"
          data-aos-easing="fade-right"
          data-aos-duration="1000"
          data-aos-anchor-placement="top-bottom"
        />
      </div>
    </div>
  </div>

  <div class="container-pay-like-pro">
    <div class="container-pay-like-pro__bg">
      <div class="container-pay-like-pro__content content-container">
        <div class="container-pay-like-pro__content__left">
          <div class="container-pay-like-pro__content__left__images">
            <img
              src="assets/images/pay-like-pro-HIW-1.webp"
              alt=""
              srcset=""
              class="img-front"
              data-aos="fade-up-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="800"
              aTilt
              [tiltSettings]="tiltSettingsImage"
            />
            <img
              src="assets/images/pay-like-pro-HIW-2.webp"
              alt=""
              srcset=""
              class="img-back"
              data-aos="fade-up-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettingsImage"
            />
            <!-- <img
              src="assets/icon/thug-life.webp"
              alt="thuglife"
              srcset=""
              class="icon-thuglife"
              data-aos="fade-down"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="800"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            /> -->
          </div>
        </div>
        <div class="container-pay-like-pro__content__right">
          <div
            class="container-pay-like-pro__content__right__title ime-heading-2"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="500"
          >
            IME Pay like a <span class="ime-color-red">pro</span>
          </div>
          <div
            class="container-pay-like-pro__content__right__sub-title ime-text-1"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="1000"
          >
            There are a lot of Bikash Nahatas out there. Find the right one fast
            — scan his QR code, and up pops his profile. No guesswork involved.
            Always pay the right person.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-do-more">
    <div class="container-do-more__content content-container">
      <div
        class="container-do-more__content__title ime-heading-1"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="300"
      >
        Get more with IME Pay
      </div>
      <div class="container-do-more__content__contents">
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
        >
          <div class="card__image">
            <img
              src="assets/images/split-fun.png"
              class="card__image__split-fun"
              alt=""
              aTilt
              [tiltSettings]="tiltSettingsImage"
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Split the fun</div>
            <div class="card__text ime-text-2">
              Settle up with IME Pay friends for any shared activity, from road
              trips to picnics to birthdays
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
        >
          <div class="card__image">
            <img
              src="assets/images/always-rewarding.png"
              class="card__image__rewards"
              alt=""
              aTilt
              [tiltSettings]="tiltSettingsImage"
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Always rewarding</div>
            <div class="card__text ime-text-2">
              Shower of rewards never stops with IME Pay. Get reward points for
              every wallet transfer you make.
            </div>
          </div>
        </div>

        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1500"
        >
          <div class="card__image handImg">
            <img
              src="assets/images/send-gift.png"
              class="card__image__send-gift"
              alt=""
              aTilt
              [tiltSettings]="tiltSettingsImage"
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Send a gift</div>
            <div class="card__text ime-text-2">
              Use IME Pay for those generous moments: celebrate a birthday, or just share your love.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-life-easy">
    <div class="container-life-easy__background">
      <div class="container-life-easy__container content-container">
        <div class="container-life-easy__left">
          <div class="container-life-easy__left__header ime-heading-7">
           More than <br />just a <span class="ime-color-red">payment</span>
          </div>
          <div class="get-app__button">
            <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
          </div>
        </div>
        <div class="container-life-easy__right">
          <div class="container-life-easy__right__container">
            <img
              src="assets/icon/message.png"
              class="container-life-easy__right__msg"
              alt=""
              data-aos="fade-down"
              data-aos-delay="800"
              data-aos-easing="fade-right"
              data-aos-duration="1000"
            />
            <img
              src="assets/images/cta-phone-wallet.png"
              class="container-life-easy__right__home-section"
              alt=""
              data-aos="fade-down"
              data-aos-delay="600"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettingsImage"
            />

            <img
              src="assets/icon/dollar.png"
              class="container-life-easy__right__gift"
              alt=""
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</div>
