import { Component, ElementRef, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { OwlOptions } from "ngx-owl-carousel-o";
import { setTiltProp } from "src/app/shared/service/utilsService/utility.service";
import { Router } from '@angular/router';
import { ROUTER_BASE_PATH } from '../../shared/constants/router-path';
import { EXTERNAL_LINKS } from "src/app/shared/constants/constants";

@Component({
  selector: "ime-how-it-works",
  templateUrl: "./how-it-works.component.html",
  styleUrls: ["./how-it-works.component.scss"],
})
export class HowItWorksComponent implements OnInit {
  routerPath =  ROUTER_BASE_PATH;
  commision_link = EXTERNAL_LINKS.CHARGE_N_COMMISION;
  customOptions: OwlOptions = {
    rtl: false,
    autoplay: true,
    loop: true,
    autoplayTimeout: 5000,
    autoplayHoverPause: false,
    autoplaySpeed: 3000,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 3000,
    slideBy: 1,
    margin: 10,
    responsive: {
      0: {
        items: 1.1,
      },
      480: {
        items: 1.1,
      },
      700: {
        items: 2,
      },
      1080: {
        items: 3,
      },
      1200: {
        items: 3,
      },
      1350: {
        items: 4,
      },
      1440: {
        items: 4,
      },
      1700: {
        items: 4,
      },
      1900: {
        items: 4,
      },
    },
    nav: false,
    autoHeight: false,
  };
  slides = [
    {
      backgroundColor:
        "linear-gradient(180deg, #F5B12C 0%, rgba(245, 177, 44, 0.48) 100%)",
      text: "Book your airlines ticket directly via app.",
      imgSrc: "assets/images/plane.webp",
      imgClass: "lifestyle-icon-plane",
    },
    {
      backgroundColor:
        "linear-gradient(180deg, #DD3333 0%, rgba(241, 81, 81, 0.48) 100%)",
      text: "Send Money to anyone using their mobile numbers.",
      imgSrc: "assets/icon/rocket.webp",
      imgClass: "lifestyle-icon-rocket",
    },
    {
      backgroundColor:
        "linear-gradient(180deg, #5193DF 0%, rgba(81, 147, 223, 0.48) 100%)",
      text: "Get  highest cashback on top-up.",
      imgSrc: "assets/icon/iPhone-l.png",
      imgClass: "lifestyle-icon-phone",
    },
    {
      backgroundColor:
        "linear-gradient(180deg, #563FE1 0%, rgba(128, 111, 232, 0.48) 100%)",
      text: "Banking has never been this easy.",
      imgSrc: "assets/icon/pig.png",
      imgClass: "lifestyle-icon-pig",
    },
    {
      backgroundColor:
        "linear-gradient(180deg, #00CC8F 0%, rgba(0, 204, 143, 0.48) 100%)",
      text: "Scan any QR and pay easily.",
      imgSrc: "assets/images/cart-alt.png",
      imgClass: "lifestyle-icon-cart",
    },
  ]
  tiltSettingsPopupImage = setTiltProp(true);

  data;
  constructor(private elementRef: ElementRef, private titleService: Title, private _router: Router) {
    this.titleService.setTitle("How it works? - Ime Pay")
    this.data = {
      slides: this.slides,
      config: this.customOptions,
    };
  }

  imgSrcLearMoreArrow = "assets/icon/learnmore-arrow.svg";
  buttonRequired = {
    getApp: true,
  };

  ngOnInit(): void {

  }
  imgSrcLock = "assets/icon/lock-locked.png";
  lockhover() {
    this.imgSrcLock = "assets/images/Lock-Left.webp";
    setTimeout(() => {
      this.imgSrcLock = "assets/icon/lock-locked.png";
    }, 1000);
  }
  remittancePath = ROUTER_BASE_PATH.REMITTANCE;
  bankTransferPath = ROUTER_BASE_PATH.BANK_TRANSFER;
  goToPage(url:string){
    this._router.navigateByUrl(url)
  }
}
