<div class="pay-service">
  <div class="pay-service-hero">
    <div class="pay-service-hero__container">
      <div class="pay-service-hero__left">
        <div class="pay-service-hero__left__content">
          <div class="pay-service-hero__left__heading ime-heading-1 ime-hide-only-on-mobile"
          data-aos="zoom-in"
          data-aos-delay="600"
          data-aos-duration="800"
          >
            Nepal’s fastest growing
            <br />
            <span class="ime-color-red">payments app</span>
          </div>
          <div class="pay-service-hero__left__heading ime-heading-1 ime-show-only-on-mobile">
            Nepal’s fastest growing
            <span class="ime-color-red">payments app</span>
          </div>
          <div class="pay-service-hero__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="800"
          >
            Recharge & pay household bills, book flights & movie tickets, renew
            your meroshare & demat account and do a lot more.
          </div>
          <div class="pay-service-hero__left__button"
          data-aos="fade-up"
          data-aos-delay="1000"
          >
            <ime-btn-get-app [data]="{ getApp: true }">GET APP</ime-btn-get-app>
          </div>
        </div>
      </div>
      <div class="pay-service-hero__right">
        <div class="pay-service-hero__right__images"
        >
          <img src="assets/images/service-flight.webp" alt="" class="pay-service-hero__right__images__popup1"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage">
          <img src="assets/images/service-mobile-recharge.webp" alt="" class="pay-service-hero__right__images__popup2"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <div class="pay-service-hero__right__images__first">
            <img
              src="assets/images/pay-service-1.webp"
              class="pay-service-hero__right__images__person pay-service-hero__right__images__person-1"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              alt=""
              aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/pay-service-3.webp"
              class="pay-service-hero__right__images__person pay-service-hero__right__images__person-3"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              alt=""
              aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="pay-service-hero__right__images__second">
            <img
              src="assets/images/pay-service-2.webp"
              class="pay-service-hero__right__images__person pay-service-hero__right__images__person-2"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/pay-service-4.webp"
              class="pay-service-hero__right__images__person pay-service-hero__right__images__person-4"
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              alt=""
              aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="pay-recharge">
    <div class="pay-recharge__container">
      <div class="pay-recharge__left">
        <div class="pay-recharge__left__images">
          <img
            src="assets/images/recharge-screen.webp"
            alt=""
            class="pay-recharge__left__images__main"
            data-aos="fade-in"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/recharge-ncell.webp"
            alt=""
            class="pay-recharge__left__images__popup1"
            data-aos="fade-down-left"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/recharge-smart.webp"
            alt=""
            class="pay-recharge__left__images__popup2"
            data-aos="fade-right"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/recharge-ntc.webp"
            alt=""
            class="pay-recharge__left__images__popup3"
            data-aos="fade-up"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
      <div class="pay-recharge__right">
        <div class="pay-recharge__right__info">
          <div class="pay-recharge__right__heading ime-heading-1"
          data-aos="zoom-in"
          data-aos-delay="200"
          >
            <span class="ime-color-red">Instant & easy</span><br />
            recharge
          </div>
          <div class="pay-recharge__right__info__text ime-text-1 ime-hide-only-on-mobile"
          data-aos="fade-up"
          data-aos-delay="400"
          >
            Your one-stop destination for online mobile recharge, landine bill &
            data pack. Talk to your loved ones endlessly without worrying about
            running out of balance. Check latest recharge plans and get many
            exciting recharge cashback offers at any time and from anywhere.
          </div>
          <div class="pay-recharge__right__info__text ime-text-1 ime-show-only-on-mobile"
          data-aos="fade-up"
          data-aos-delay="400"
          >
          Your one-stop destination for online mobile recharge, landine bill & data pack. Talk to your loved ones endlessly without worrying about running out of balance.
          </div>
          <div class="pay-recharge__right__info__logo"
          data-aos="fade-left"
          data-aos-delay="600"
          >
            <div class="pay-recharge__right__info__logo-box">
              <img src="assets/images/recharge-mobile.webp" alt=""
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
            <div class="pay-recharge__right__info__logo-2">
              <img src="assets/images/recharge-landline.webp" alt=""
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
            <div class="pay-recharge__right__info__logo-3">
              <img src="assets/images/recharge-data-pack.webp" alt=""
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="pay-travel">
    <div class="pay-travel__container">
      <div class="pay-travel__left">
        <div class="pay-travel__left__content">
          <div class="ppay-travel__left__heading ime-heading-1"
          data-aos="zoom-in"
          data-aos-delay="600"
          data-aos-duration="800"
          >
            <span class="ime-color-red">Travel in style </span>
            <br />
            with IME Pay
          </div>
          <div class="pay-travel__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-delay="800"
          >
          From bargain to luxury, it’s easy to discover and buy amazing domestic flight & cable-car tickets in minutes. Experience one app for all things travel.
          </div>
          <div class="pay-travel__left__logo"
          data-aos="fade-up"
          data-aos-delay="1000"
          >
            <img src="assets/images/travel-cable-car.png" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            />
            <img src="assets/images/travel-flight.png" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
      <div class="pay-travel__right">
        <div class="pay-travel__right__images">
          <img
            src="assets/images/travel-siddharth.webp"
            alt=""
            class="pay-travel__right__images__popup1"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/travel-yeti.webp"
            alt=""
            class="pay-travel__right__images__popup2"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/travel-chandragiri.webp"
            alt=""
            class="pay-travel__right__images__popup3"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <div class="pay-travel__right__images__first">
            <img
              src="assets/images/travel-service-1.webp"
              class="pay-travel__right__images__person pay-travel__right__images__person-1"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/travel-service-3.webp"
              class="pay-travel__right__images__person pay-travel__right__images__person-3"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="pay-travel__right__images__second">
            <img
              src="assets/images/travel-service-2.webp"
              class="pay-travel__right__images__person pay-travel__right__images__person-2"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/travel-service-4.webp"
              class="pay-travel__right__images__person pay-travel__right__images__person-4"
              alt=""
              data-aos="fade-up"
              data-aos-duration="800"
              data-aos-delay="200"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- go-to -->
  <div class="bank-link-steps content-container">
    <div
      class="bank-link-steps__header ime-heading-1"
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="800"
      data-aos-delay="100"
    >
      The go-to app for travellers
    </div>
    <div class="bank-link-steps__container steps">
      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="200"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/travel-step-1.webp"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            alt=""
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--first ime-heading-3"
          >
            Search
          </div>
          <div class="steps__step__info ime-text-1">
            From flights to cable cars, IME Pay is the ideal way to find your
            travel ticket. You can find the best of deals and cheap tickets to
            any place you want.
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="500"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/travel-step-2.webp"
            class="steps-icon-enter-details"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--second ime-heading-3"
          >
            Compare
          </div>
          <div class="steps__step__info ime-text-1">
            We serve you the cheapest ticket, & highest cashback possible! Don’t
            just take our word for it, try & get a little more from what you
            love.
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="800"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/travel-step-3.webp"
            class="steps-icon-link"
            alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--third ime-heading-3"
          >
            Buy
          </div>
          <div class="steps__step__info ime-text-1">
            Experience great value at the lowest price. Instant notifications
            ensure fare drops, amazing discounts, instant refunds and
            rescheduling options.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bill-service">
    <div class="bill-service__container">
      <div class="bill-service__left">
        <div class="bill-service__left__images">
          <img
            src="assets/images/electricity.webp"
            alt=""
            class="bill-service__left__images__main"
            data-aos="zoom-in-right"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/dish-home.webp"
            alt=""
            class="bill-service__left__images__main2"
            data-aos="zoom-in-left"
            data-aos-delay="200"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/go-green.webp"
            alt=""
            class="bill-service__left__images__popup1"
            data-aos="zoom-out-down"
            data-aos-delay="300"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/wifi-bill.webp"
            alt=""
            class="bill-service__left__images__popup2"
            data-aos="zoom-out-up"
            data-aos-delay="400"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
      <div class="bill-service__right">
        <div class="bill-service__right__info">
          <div class="bill-service__right__info__heading ime-heading-1"
          data-aos="zoom-in-up"
          data-aos-delay="400"
          >
            Pay all your<br />
            <span class="ime-color-red">Household Bills</span>
          </div>
          <div class="bill-service__right__info__logos">
            <img
              src="assets/images/service-electricity.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="550"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-internet.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="700"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-water-bill.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="850"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-waste-management.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1000"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-television.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1150"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-education.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1300"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-vehicle.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1450"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            /><img
              src="assets/images/service-credit-card.webp"
              alt=""
              class="bill-service__right__info__logos__logo"
              data-aos="zoom-out"
              data-aos-delay="1500"
              data-aos-duration="150"
              aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="entertainment-service">
    <div class="entertainment-service__container">
      <img src="assets/images/service-liv.webp" alt="" class="entertainment-service__popup1 ime-hide-only-on-mobile"
      data-aos="fade-down-right"
      aTilt
          [tiltSettings]="tiltSettingsPopupImage"
      >
      <img src="assets/images/service-qfx.webp" alt="" class="entertainment-service__popup2 ime-hide-only-on-mobile"
      data-aos="fade-up-left"
      aTilt
          [tiltSettings]="tiltSettingsPopupImage"
      >
      <div class="entertainment-service__heading ime-heading-1"
      data-aos="zoom-out"
      data-aos-delay="200"
      >
       <span class="ime-color-red">Entertainment</span> on IME Pay
      </div>
      <div class="entertainment-service__text ime-text-1"
      data-aos="zoom-out-up"
      data-aos-delay="400"
      >
        Book and buy tickets to your favourite movies and events, make OTT
        subscription payments and votings easier, all directly from the IME Pay
        app.
      </div>
      <div class="entertainment-service__logos ime-hide-only-on-mobile"
      data-aos="zoom-out-up"
      data-aos-delay="600"
      >
        <img src="assets/images/service-movies.webp" alt="" class="entertainment-service__logos__logo"
        aTilt
        [tiltSettings]="tiltSettingsPopupImage"
        />
        <img src="assets/images/service-ott.webp" alt="" class="entertainment-service__logos__logo"
        aTilt
        [tiltSettings]="tiltSettingsPopupImage"
        />
        <img
          src="assets/images/service-events.webp"
          alt=""
          class="entertainment-service__logos__logo"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <img src="assets/images/service-voting.webp" alt="" class="entertainment-service__logos__logo"
        aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
      <div class="entertainment-service__animation ime-hide-only-on-mobile">
        <ime-json-animator
              [options]="animationIconOptions[0]"
            ></ime-json-animator>
      </div>
      <div class="entertainment-service__animation ime-show-only-on-mobile">
        <ime-json-animator
              [options]="animationIconOptions[1]"
            ></ime-json-animator>
      </div>
    </div>
  </div>

  <div class="more-payment">
    <div class="more-payment__container">
      <div class="more-payment__left">
        <div class="more-payment__left__info">
          <div class="more-payment__left__heading ime-heading-1"
          data-aos="zoom-out"
          data-aos-delay="400"
          >
            <span class="ime-color-red">More payments,</span><br>
            all in one place
          </div>
          <div class="more-payment__left__text ime-text-1"
          data-aos="zoom-out-up"
          >
            IME Pay has established its identity as one of the most reputed mobile finance solutions. And the biggest reason behind it is the number of benefits that it provides.
          </div>
          <div class="more-payment__left__logos"
          >
            <img src="assets/images/service-payment.webp" alt=""
            data-aos="zoom-out-right"
            data-aos-delay="500"
            data-aos-duration="150"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/service-insurance.webp" alt=""
            data-aos="zoom-out-left"
            data-aos-delay="650"
            data-aos-duration="150"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/service-share.webp" alt=""
            data-aos="zoom-out-right"
            data-aos-delay="800"
            data-aos-duration="150"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/service-government.webp" alt=""
            data-aos="zoom-out-left"
            data-aos-delay="950"
            data-aos-duration="150"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
        </div>
      </div>
      <div class="more-payment__right">
        <div class="more-payment__right__images">
          <img src="assets/images/demat.webp" alt="" class="more-payment__right__images__main1">
          <img src="assets/images/banks.webp" alt="" class="more-payment__right__images__main2"
          data-aos="zoom-out-right"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/service-meroshare.webp" alt="" class="more-payment__right__images__popup1"
          data-aos="fade-down-left"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/service-igi.webp" alt="" class="more-payment__right__images__popup2"
          data-aos="fade-right"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
          <img src="assets/images/service-traffic.webp" alt="" class="more-payment__right__images__popup3"
          data-aos="fade-up-left"
          data-aos-delay="200"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
        </div>
      </div>
    </div>
  </div>

  <div class="payment-benefit">
    <div class="payment-benefit__heading"
    data-aos="zoom-out"
    data-aos-delay="500"
    >
      No Lines, No Fines
    </div>
    <div class="payment-benefit__container">
      <div class="payment-benefit__cards">
        <div class="payment-benefit__card"
        data-aos="fade-up"
        data-aos-delay="600"
        >
          <div class="payment-benefit__card__image">
            <img src="assets/images/save-time.webp" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage">
          </div>
          <div class="payment-benefit__card__info">
            <div class="payment-benefit__card__heading">
              Save time
            </div>
            <div class="payment-benefit__card__text ime-text-2">
              Forget about staying in hours of lines in electricity, traffic police, government offices etc. for making payments.
            </div>
          </div>
        </div>
        <div class="payment-benefit__card"
        data-aos="fade-up"
        data-aos-delay="800"
        >
          <div class="payment-benefit__card__image">
            <img src="assets/images/get-rebates.webp" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
          <div class="payment-benefit__card__info">
            <div class="payment-benefit__card__heading">
              Get rebates
            </div>
            <div class="payment-benefit__card__text ime-text-2">
              Use IME Pay to pay your utility bills on time and get instant rebates. From electricity bills to municipal tax, forget fines!!!
            </div>
          </div>
        </div>
        <div class="payment-benefit__card"
        data-aos="fade-up"
        data-aos-delay="800"
        >
          <div class="payment-benefit__card__image">
            <img src="assets/images/pay-amount.webp" alt=""
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
          <div class="payment-benefit__card__info">
          <div class="payment-benefit__card__heading">
            Cashbacks & rewards
          </div>
          <div class="payment-benefit__card__text ime-text-2">
            Amazing discounts, offers and rewards. Don’t just take our word for it, try & get a little more from what you love.
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="service-cta">
    <div class="service-cta__container">
      <div class="service-cta__wrapper">
        <div class="service-cta__left">
          <div class="service-cta__left__heading"
          data-aos="zoom-in"
          data-aos-delay="300"
          >
            Quick, simple
            <br>
            and <span class="ime-color-red">convenient</span>
          </div>
          <div class="service-cta__left__button"
          data-aos="fade-up"
          data-aos-delay="500"
          >
            <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
          </div>
        </div>
        <div class="service-cta__right">
          <div class="service-cta__right__images">
            <img src="assets/images/menu.webp" alt="" class="service-cta__right__images__main1"
            data-aos="fade-up-left"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
            <img src="assets/images/traffic.webp" alt="" class="service-cta__right__images__main2"
            data-aos="fade-up-right"
            aTilt
          [tiltSettings]="tiltSettingsPopupImage"
            >
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</div>
