<section class="remit-page">
  <div class="remit-page__remit content-container">
    <div class="remit-page__remit__left">
      <div class="remit-page__remit__left__img">
        <div class="remit-page__remit__left__img__container">
          <img
            src="assets/images/remit-with-ime.webp"
            class="img__banner"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="200"
            loading="eager"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <img
          src="assets/images/money-gram.webp"
          class="icon-money-gram"
          alt=""
          data-aos="fade-up-left"
          data-aos-easing="ease-out-sine"
          data-aos-duration="700"
          data-aos-delay="700"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <img
          src="assets/images/plane.webp"
          class="remit-icon-plane"
          alt=""
          data-aos="fade-up-right"
          data-aos-easing="ease-out-sine"
          data-aos-duration="700"
          data-aos-delay="500"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>
    </div>
    <div class="remit-page__remit__right">
      <div class="remit-page__remit__right__content">
        <div
          class="remit-page__remit__right__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="700"
          data-aos-anchor-placement="top-bottom"
        >
          <span>Remit</span> with <br />
          IME Pay
        </div>
        <div
          class="remit-page__remit__right__title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="300"
          data-aos-anchor-placement="top-bottom"
        >
          The free, fast way to receive remittance money from 200+ countries &
          send across Nepal. We provide easy withdrawals of received remittance
          from 30,000+ IME Agents or from any ATM using IME Visa card .
        </div>

        <div
          class="remit-page__remit__right__button"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__recieve content-container">
    <div class="remit-page__recieve__info">
      <div class="remit-page__recieve__info__content">
        <div
          class="remit-page__recieve__info__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          Receive <br /><span class="ime-color-red">remittance</span>
        </div>
        <div
          class="remit-page__recieve__info__title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          Instantly receive money sent from over 200+ countries directly on your
          IME Pay account in 4 easy steps.
        </div>
      </div>
    </div>
    <div class="remit-page__recieve__figure">
      <div class="remit-page__recieve__figure__image">
        <img
          src="assets/images/remittance-mobile_716_1364.webp"
          class="remit-receive"
          alt=""
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="300"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div
          class="remit-icon-partner"
          data-aos="fade-up-right"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="700"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        >
          <ime-json-animator
            [options]="animationIconOptions"
          ></ime-json-animator>
        </div>
      </div>
    </div>
    <div class="remit-page__recieve__method">
      <div class="remit-page__recieve__method__steps steps">
        <div class="steps__step">
          <div
            class="steps__step__header steps__step__header--first ime-heading-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="300"
          >
            Select sender country
          </div>
          <div
            class="steps__step__info ime-text-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="900"
            data-aos-delay="400"
          >
            from where you are receiving the remittance.
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header steps__step__header--second ime-heading-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="800"
          >
            Enter Control Number
          </div>
          <div
            class="steps__step__info ime-text-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="900"
          >
            of 12 - 16 digits received from the sender.
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header steps__step__header--third ime-heading-5"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="1200"
          >
            Enter Amount
          </div>
          <div
            class="steps__step__info ime-text-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="800"
            data-aos-delay="1300"
          >
            you are expecting from the sender.
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header steps__step__header--fourth ime-heading-6"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="1500"
          >
            Notification & Bonus
          </div>
          <div
            class="steps__step__info steps__step__info--last ime-text-3"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="1600"
          >
            are instantly provided along with rewards.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__track">
    <div class="remit-page__track__container content-container">
      <div class="remit-page__track__container__left">
        <div class="remit-page__track__container__left__images">
          <img
            src="assets/images/track-money.webp"
            class="track-money-img"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/icon/eye.png"
            class="track-money-icon-eye"
            aTilt
            [tiltSettings]="tiltSettingsPopupEye"
          />
        </div>
      </div>
      <div class="remit-page__track__container__right">
        <div class="remit-page__track__container__right__content">
          <div
            class="remit-page__track__container__right__content__heading ime-heading-1"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
          >
            Track your<br />
            <span>money</span>
          </div>
          <div
            class="remit-page__track__container__right__content__title ime-text-1"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            Track the status of your remittance payments in real time, directly
            in app. The steps are as simple as receiving.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__network ime-hide-only-on-mobile">
    <div class="remit-page__network__info">
      <div class="remit-page__network__info__cards cards content-container">
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="100"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-anchor.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">77</div>
          <div class="cards__card__header">District Presence</div>
        </div>
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="300"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-sign.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">35K+</div>
          <div class="cards__card__header">Agents Network</div>
        </div>
        <div
          class="cards__card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="600"
        >
          <div class="cards__card__image">
            <img src="assets/icon/icon-bolt.png" alt="" />
          </div>
          <div class="cards__card__stats ime-heading-1">3M+</div>
          <div class="cards__card__header">Users Community</div>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__network ime-show-only-on-mobile">
    <div
      *ngFor="let slide of remittanceNetwork"
      class="remit-page__network__box"
    >
      <div class="network-card">
        <div class="network-card__image">
          <img [src]="slide.img" alt="" />
        </div>
        <div class="network-card__stats ime-heading-1">
          {{ slide.title }}
        </div>
        <div class="network-card__header">{{ slide.desc }}</div>
      </div>
    </div>
  </div>
  <div class="remit-page__remit-steps content-container">
    <div
      class="remit-page__remit-steps__header ime-heading-1 ime-hide-only-on-mobile"
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="800"
      data-aos-delay="100"
    >
      Send remittance for free
    </div>
    <div
      class="remit-page__remit-steps__header ime-heading-1 ime-show-only-on-mobile"
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="800"
      data-aos-delay="100"
    >
      Send remittance <br>for free
    </div>
    <div class="remit-page__remit-steps__container steps">
      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="200"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/receiver-contact.webp"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            alt=""
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--first ime-heading-3"
          >
            Receiver contact
          </div>
          <div class="steps__step__info ime-text-1">
            A receiver mobile number is enough to send domestic remittance for
            free.
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="500"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/send-money-enter-details.webp"
            class="steps-icon-enter-details ime-hide-only-on-mobile"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/send-money-enter-details-mobile.webp"
            class="steps-icon-enter-details ime-show-only-on-mobile"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--second ime-heading-3"
          >
            Receiver details
          </div>
          <div class="steps__step__info ime-text-1">
            Enter the amount you want to send and provide receiver details.
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="800"
      >
        <div class="steps__step__icon">
          <img src="assets/icon/rocket.webp" class="steps-icon-rocket" alt="" />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--third ime-heading-3"
          >
            Send money
          </div>
          <div class="steps__step__info ime-text-1">
            The remittance control number are sent as text into receiver’s
            mobile phone!!!Hurray!!!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="remit-page__partners content-container ime-hide-only-on-mobile">
    <div class="remit-page__partners__contents">
      <div class="remit-page__partners__contents__top">
        <div class="remit-page__partners__left">
          <div class="remit-page__partners__left__header">
            Partner Institutions
          </div>
          <div
            class="remit-page__partners__left__types"
            data-aos="fade-up-right"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="700"
            data-aos-delay="400"
          >
            International Partners
          </div>
          <div
            class="remit-page__partners__left__types"
            data-aos="fade-up-right"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="800"
            data-aos-delay="600"
          >
            Domestic Partners
          </div>
        </div>
        <div class="remit-page__partners__right">
          <div
            class="remit-page__partners__right__heading ime-heading-2"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="700"
            data-aos-delay="200"
          >
            Find your favourite remit partner at IME Pay.
          </div>
          <div
            class="remit-page__partners__right__text ime-text-2--alt"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="800"
            data-aos-delay="400"
          >
            We have brought all international as well as domestic remittance
            companies under one roof. So that you you can receive money form
            200+ countries and withdraw cash form 35000+ agents.
          </div>
        </div>
      </div>
    </div>
    <div class="remit-page__partners__bottom">
      <div *ngFor="let image of allPatners; let i = index">
        <img
          [src]="image"
          alt=""
          srcset=""
          data-aos="fade-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
          [attr.data-aos-delay]="i * 50 + 50"
        />
      </div>
    </div>
  </div>
  <div class="partners content-container ime-show-only-on-mobile">
    <div class="partners__contents">
      <div class="partners__contents__info">
        <div class="partners__contents__title ime-heading-2">
          Find your favourite remit partner at IME Pay.
        </div>
        <div class="partners__contents__label ime-text-2--alt">
          We have brought all international as well as domestic remittance
          companies under one roof. So that you you can send money form more
          than 133 countries and receive form 33000+ agents
        </div>
      </div>
      <div class="partners__contents__intl-patners intl-patners">
        <div class="intl-patners__subtitle">International Partners</div>
        <div class="intl-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template
              carouselSlide
              *ngFor="let slide of internationalPatners"
            >
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="partners__contents__domestic-patners domestic-patners">
        <div class="domestic-patners__subtitle">Domestic Companies</div>
        <div class="domestic-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template carouselSlide *ngFor="let slide of domesticPatners">
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
  <div class="container-hidden-fee content-container">
    <div class="container-hidden-fee__box">
      <div class="container-hidden-fee__box__title"
      data-aos="custom-zoom-in"
      data-aos-delay="400"
      data-aos-duration="1000"
      >
        No Hidden fees
      </div>
      <img src="assets/icon/pig-no-fee.png" class="icon__pig" alt="" />
      <div
        class="container-hidden-fee__box__subtitle ime-text-4"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="800"
        data-aos-delay="1300"
        data-aos-anchor-placement="top-bottom"
      >
        It costs nothing to send or claim remittance on your IME Pay account.
      </div>
      <div
        class="container-hidden-fee__box__learn-more-link"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="800"
        data-aos-delay="1400"
        data-aos-anchor-placement="top-bottom"
      >
        <a [href]="commision_link" target="_blank">LEARN MORE</a>
      </div>
      <div class="rupees-section">
        <div
          class="rupees-section__wallet-transfer"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
          data-aos-delay="1500"
          data-aos-anchor-placement="top-bottom"
        >
          <span class="charge-rs">Rs. 0</span>
          <p>Receive Remittance</p>
        </div>
        <div
          class="rupees-section__remmittance"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1200"
          data-aos-delay="1600"
          data-aos-anchor-placement="top-bottom"
        >
          <span class="charge-rs">Rs. 0</span>
          <p>Send Remittance</p>
        </div>
      </div>
      <span
        data-aos="fade-up"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="1000"
        data-aos-delay="1800"
        data-aos-anchor-placement="top-bottom"
        class="ime-hide-only-on-mobile"
      >
        *IME Pay does not charge for sending remittance throughout Nepal.<br />
        However the remittance receiver has to pay if he/she withdraws cash from
        our agent network.Receiving remittance on mobile wallet is always free.
      </span>
      <span class="ime-show-only-on-mobile">*T&C Apply</span>
      <img
        src="assets/images/Hand.webp"
        class="icon__hand"
        alt=""
        data-aos="fade-left"
        data-aos-easing="ease-out-sine"
        data-aos-duration="600"
        data-aos-delay="900"
        data-aos-anchor-placement="top-bottom"
      />
      <img
        src="assets/images/plane.webp"
        class="icon__plane"
        alt=""
        data-aos="fade-down"
        data-aos-delay="1100"
        data-aos-easing="fade-right"
        data-aos-duration="1000"
        data-aos-anchor-placement="top-bottom"
      />
    </div>
  </div>

  <div class="container-pay-like-pro">
    <div class="container-pay-like-pro__bg">
      <div class="container-pay-like-pro__content content-container">
        <div class="container-pay-like-pro__content__left">
          <div class="container-pay-like-pro__content__left__images">
            <img
              src="assets/images/remit-like-pro-2.webp"
              alt=""
              srcset=""
              class="img-front"
              data-aos="fade-up-right"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="100"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/remit-like-pro-1.webp"
              alt=""
              srcset=""
              class="img-back"
              data-aos="fade-up-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="100"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/Lightning.webp"
              alt="lightning"
              srcset=""
              class="icon-lightning"
              data-aos="fade-down"
              data-aos-easing="ease-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="800"
            />
          </div>
        </div>
        <div class="container-pay-like-pro__content__right">
          <div
            class="container-pay-like-pro__content__right__title ime-heading-2"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="1000"
          >
            Fast, Safe & <span class="ime-color-red">Reliable </span>
          </div>
          <div
            class="container-pay-like-pro__content__right__sub-title ime-text-1"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-quart"
            data-aos-duration="1000"
            data-aos-delay="400"
          >
            Join 15 million people who receive IME Remittance & get bonus when
            they receive money from abroad on IME Pay.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-do-more">
    <div class="container-do-more__content content-container">
      <div
        class="container-do-more__content__title ime-heading-1 ime-hide-only-on-mobile"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="300"
      >
        Receive without borders
      </div>
      <div
        class="container-do-more__content__title ime-heading-1 ime-show-only-on-mobile"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="300"
      >
        Receive without <br/>borders
      </div>
      <div class="container-do-more__content__contents">
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
        >
          <div class="card__image redimImg"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/secure-sheild.webp"
              class="card__image__sheild"
              alt=""
              srcset=""
            />
            <img
              src="assets/images/robot.webp"
              class="card__image__reward-point"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Anti-fraud algorithms</div>
            <div class="card__text ime-text-2 card__text--large">
              Our tech-team have developed an advance algorithms to keep
              receiving process protected from even the most sophisticated
              fraud.
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
        >
          <div class="card__image mobileTopUpImg"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/get-cash-bonus.webp"
              class="card__image__cash-back"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Get Cash Bonus</div>
            <div class="card__text ime-text-2">
              Cross border remittance receiver always get extra love from IME.
              We shower them with extra cash bonus and reward points. Cheery on
              top!!!
            </div>
          </div>
        </div>

        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1500"
        >
          <div class="card__image handImg"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/find-near-by.webp"
              class="card__image__free-transaction"
              alt=""
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Find nearby Agents</div>
            <div class="card__text ime-text-2">
              Our network of 35000+ Agents are always there for our valued
              customers. They help your remittance receiver withdraw cash
              easily.
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-life-easy">
    <div class="container-life-easy__background">
      <div class="container-life-easy__container content-container">
        <div class="container-life-easy__left">
          <div class="container-life-easy__left__header ime-heading-7">
            Move your<br />money <span class="ime-color-red">easily</span>
          </div>
          <div class="get-app__button">
            <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
          </div>
        </div>
        <div class="container-life-easy__right">
          <div class="container-life-easy__right__container">
            <div class="container-life-easy__right__container__img">
              <img
                src="assets/images/more-with-money-2.webp"
                class="container-life-easy__right__container__img__front"
                alt=""
                data-aos="fade-down"
                data-aos-delay="600"
                data-aos-easing="ease-in-out-back"
                data-aos-duration="1000"
                aTilt
                [tiltSettings]="tiltSettingsPopupImage"
              />
              <img
                src="assets/images/more-with-money-1.webp"
                class="container-life-easy__right__container__img__back"
                alt=""
                data-aos="fade-down"
                data-aos-delay="400"
                data-aos-easing="ease-in-out-back"
                data-aos-duration="1000"
                aTilt
                [tiltSettings]="tiltSettingsPopupImage"
              />
            </div>
            <img
              src="assets/images/plane.webp"
              class="container-life-easy__right__plane"
              alt=""
              data-aos="fade-down"
              data-aos-delay="800"
              data-aos-easing="fade-right"
              data-aos-duration="1000"
            />

            <img
              src="assets/images/Gift_Left.webp"
              class="container-life-easy__right__gift"
              alt=""
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</section>
