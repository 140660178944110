<section class="landing-page" #landingPageDiv>
  <div class="landing-page__header">
    <div class="landing-page__header__container content-container">
      <div class="landing-page__header__get-app">
        <div class="get-app__contents">
          <div
            class="get-app__header ime-heading-1 aos "
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
          >
            Making <span id="text__animate"></span>
            <br />

            <div style="white-space: nowrap">
              <span>
                <span>safe, </span>
                <span>simple</span>
                &
                <span>smarter</span
                >
              </span>
            </div>
          </div>
          <div
            class="get-app__text ime-text-1 aos"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
          >
            Trusted by more than<span
              class="ime-color-red"
              style="font-weight: 600"
            >

              3 Million</span
            >
            users.
          </div>
          <div
            class="get-app__button aos"
            data-aos="fade-up"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1600"
          >
            <ime-btn-get-app [data]="buttonRequired"></ime-btn-get-app>
          </div>
        </div>
      </div>
      <div
        class="landing-page__header__hero-section aos"
        data-aos="fade-right"
        data-aos-easing="ease-out-cubic"
        data-aos-duration="1500"
      >
        <div class="landing-page__header__hero-section__container">
          <img
            src="assets/images/landing-hero-mobile.webp"
            class="landing-page__header__hero-section__hero"
            alt=""
            loading="eager"
          />

          <img
            src="assets/images/visa-card-shadow-right.webp"
            class="landing-page__header__hero-section__card"
            alt=""
            loading="eager"
          />
          <img
            src="assets/icon/emoji-lov.png"
            class="landing-page__header__hero-section__light"
            alt=""
            data-aos="fade-down-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1500"
            loading="eager"
          />
          <img
            src="assets/icon/play-button.svg"
            class="landing-page__header__hero-section__play"
            alt=""
            loading="eager"
            (click)="sendUrl(imeInfoYoutubeLink)"
          />
        </div>
      </div>
    </div>
    <!-- <img
      src="assets/icon/scroll.svg"
      class="scroll-btn ime-hide-only-on-mobile"
      alt=""
      *ngIf="showAutoScrollBtn"
    /> -->
    <div class="landing-page__header__spacer ime-hide-only-on-mobile"></div>
  </div>

  <!-- <div class="landing-page__why-us" #whyUsDiv>
    <div class="why-us__cards content-container" #whyUsDiv>
      <div
        class="why-us__card"
        data-aos="zoom-in-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="500"
      >
        <div class="why-us__card__image rocket">
          <img src="assets/icon/rocket.webp" alt="" />
        </div>
        <div class="why-us__card__info">
          <div class="why-us__card__heading ime-heading-3">Send</div>
          <div class="why-us__card__text ime-text-1--alt">
            Send money to any mobile number or flash QR codes and tell friends
            to “IME Pay me!”
          </div>
        </div>
      </div>
      <div
        class="why-us__card"
        data-aos="zoom-in-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="1000"
      >
        <div class="why-us__card__image">
          <img src="assets/images/phone.webp" class="phone" alt="" />
          <img
            src="assets/images/visa-card-small.webp"
            class="phone__popup"
            alt=""
          />
        </div>
        <div class="why-us__card__info">
          <div class="why-us__card__heading ime-heading-3">Spend</div>
          <div class="why-us__card__text ime-text-1--alt ime-hide-only-on-mobile">
            Shop at thousands of physical stores & online sites or apps using
            <br />IME Pay balance.
          </div>
          <div class="why-us__card__text ime-text-1--alt ime-show-only-on-mobile">
            Shop at thousands of physical stores & online sites or apps using IME Pay balance.
          </div>
        </div>
      </div>
      <div
        class="why-us__card"
        data-aos="zoom-in-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="1500"
      >
        <div class="why-us__card__image">
          <img src="assets/icon/piggy.webp" class="bank" alt="" />
          <img
            src="assets/icon/silver-coin.png"
            class="bank__coin"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="200"
          />
        </div>
        <div class="why-us__card__info">
          <div class="why-us__card__heading ime-heading-3">Bank</div>
          <div class="why-us__card__text ime-text-1--alt">
            Make instant deposits to any bank account. Link all your accounts to
            IME Pay.
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- pay-to-friend -->
  <div class="pay-to-friend-container">
    <div class="pay-to-friend content-container">
      <div class="pay-to-friend__left">
        <div
          class="pay-to-friend__left__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-easing="ease-out-cubic"
        >
          IME Pay to<br />
          <span class="ime-color-red"> Friends </span>
        </div>

        <div
          class="pay-to-friend__left__desc ime-text-1"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="300"
          data-aos-easing="ease-out-cubic"
        >
          Send or request payments to friends & family anytime, anywhere. It’s
          fast and free, and a mobile number is all you need to get started.
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1600"
          [routerLink]="routerPath.WALLET_TRANSFER"
          routerLinkActive="router-link-active"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">Learn more</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>
      <div class="pay-to-friend__middle">
        <div class="pay-to-friend__middle__img">
          <img
            src="assets/images/friends-to.webp"
            class="pay-to-friend__middle__img__main-img"
            alt=""
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="100"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/friend-request.webp"
            class="pay-to-friend__middle__icon-friend-request"
            alt=""
            data-aos="fade-up-left"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/friend-invite.webp"
            class="pay-to-friend__middle__icon-friend-invite"
            alt=""
            data-aos="fade-up-right"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
      <div class="pay-to-friend__right">
        <div class="pay-to-friend__right__how-to">
          <div
            class="pay-to-friend__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="300"
          >
            Find your Friends
          </div>
          <div
            class="pay-to-friend__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="500"
          >
            using their mobile number, QR codes or unique username found on
            their profile.
          </div>
          <div
            class="pay-to-friend__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="800"
          >
            Express yourself
          </div>

          <div
            class="pay-to-friend__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1000"
          >
            in each payment. Share a reason, emojis or an inside joke. It’s up
            to you.
          </div>

          <div
            class="pay-to-friend__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1300"
          >
            Send or Request
          </div>
          <div
            class="pay-to-friend__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1500"
          >
            payments to your friends & family & get the fun of sending right
            into your notifications.
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end pay to friend -->
  <!-- start remit with ime pay -->
  <div class="remit-container">
    <div class="remit content-container">
      <div class="remit__left">
        <div
          class="remit__left__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-duration="800"
          data-aos-delay="100"
          data-aos-easing="ease-out-cubic"
        >
          <span class="ime-color-red"> Remit </span> with <br />IME Pay
        </div>

        <div
          class="remit__left__desc ime-text-1"
          data-aos="fade-up"
          data-aos-duration="1200"
          data-aos-delay="300"
          data-aos-easing="ease-out-cubic"
        >
          Receive money from 200+ countries worldwide & send money across Nepal.
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1600"
          [routerLink]="routerPath.REMITTANCE"
          routerLinkActive="router-link-active"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">Learn more</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>
      <div class="remit__middle">
        <div class="remit__middle__img">
          <img
            src="assets/images/remit-with-ime.webp"
            class="remit__middle__img__main-img"
            alt=""
            data-aos="fade-up"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="100"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/with-ime-recieve.webp"
            class="remit__middle__icon-remit-receive"
            alt=""
            data-aos="fade-up-left"
            data-aos-duration="800"
            data-aos-easing="ease-in-sine"
            data-aos-delay="400"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <div
            class="remit__middle__icon-remit-list"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          >
            <ime-json-animator
              class="remit__middle__icon-remit-list__json"
              [options]="animationIconOptions[0]"
            ></ime-json-animator>
          </div>
        </div>
      </div>
      <div class="remit__right">
        <div class="remit__right__how-to">
          <div
            class="remit__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="300"
          >
            Receive Remittance
          </div>
          <div
            class="remit__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="500"
          >
            directly in your IME Pay wallet and track the payment status easily.
          </div>
          <div
            class="remit__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-in-sine"
            data-aos-delay="800"
          >
            Send Domestic Remittance
          </div>

          <div
            class="remit__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1000"
          >
            to any mobile number inside Nepal.
          </div>

          <div
            class="remit__right__how-to__title ime-heading-6"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1300"
          >
            Withdraw Cash
          </div>
          <div
            class="remit__right__how-to__label ime-text-3--alt"
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-easing="ease-out-cubic"
            data-aos-delay="1500"
          >
            using “Find Agents” or IME VISA card on any ATM throughout Nepal &
            India.
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- End remit with ime pay -->
  <div class="container__features" #accountTransferDiv>
    <div class="container__features__feature feature content-container">
      <div class="feature__content">
        <div
          class="feature__content__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
        >
          Bank & <span>Transfers</span>
        </div>
        <div
          class="feature__content__text ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1500"
        >
          Move money between IME Pay and your bank account, so your money is
          exactly where you want it, when you want it.
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1600"
          [routerLink]="routerPath.BANK_TRANSFER"
          routerLinkActive="router-link-active"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">Learn more</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>
      <div class="feature__figure">
        <div class="feature__figure__transfer">
          <img
            src="assets/images/landing-page-bank.webp"
            class="feature__figure__transfer-1"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="600"
            aTilt
            [tiltSettings]="tiltSettings"
          />
          <div class="feature__figure__transfer-2" aTilt>
            <ime-json-animator
              [options]="animationIconOptions[1]"
            ></ime-json-animator>
          </div>
          <img
            src="assets/icon/piggy.webp"
            class="feature__figure__transfer-pig pig"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="200"
          />
          <img
            src="assets/icon/silver-coin.png"
            class="feature__figure__transfer-coin coin"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="200"
          />
        </div>
      </div>
      <div class="feature__details">
        <div
          class="feature__details__detail"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
        >
          <div class="feature__details__heading ime-heading-6">
            Bank Transfer
          </div>
          <div class="feature__details__text ime-text-3">
            lets you deposit your money to any bank account across the country.
          </div>
        </div>

        <div
          class="feature__details__detail"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1500"
        >
          <div class="feature__details__heading ime-heading-6">
            Co-operative Transfer
          </div>
          <div class="feature__details__text ime-text-3">
            helps to make your savings easier and faster with real time
            deposits.
          </div>
        </div>

        <div
          class="feature__details__detail"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1600"
        >
          <div class="feature__details__heading ime-heading-6">
            Micro-finance Transfer
          </div>
          <div class="feature__details__text ime-text-3">
            has never been so easy. Instant deposits to any Micro-finance
            account is now at your fingertips.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="landing-page__interest" #interestDiv>
    <div class="landing-page__interest__background">
      <div class="landing-page__interest__contents content-container">
        <div
          class="landing-page__interest__contents__left"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
        >
          <div class="landing-page__interest__contents__left__heading">
            {{ interestRate }}%
          </div>
          <div class="landing-page__interest__contents__left__text">
            *For Super Wallet Account only.
          </div>
          <div
            class="landing-page__interest__contents__left__gift"
            data-aos="fade-right"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
          >
            <img src="assets/icon/fire.png" alt="" />
          </div>
        </div>
        <div class="landing-page__interest__contents__right">
          <div class="landing-page__interest__contents__right__info">
            <div
              class="landing-page__interest__contents__right__heading ime-heading-2"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="500"
            >
              <span class="ime-color-red">Earn Interest</span> only <br />on IME
              Pay Wallet
            </div>
            <div
              class="landing-page__interest__contents__right__text ime-text-1"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="500"
            >
            Get {{ interestRate < 7 ? currentInterestRate : currentInterestRate }}% interest, paid daily, disbursed quarterly.
            </div>

            <button
              mat-stroked-button
              class="learn-more-btn"
              (mouseover)="
                imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
              "
              (mouseout)="
                imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'
              "
              (click)="
                goToLink(
                  'https://blog.imepay.com.np/better-features-updated-interest-an-all-new-look-of-your-favorite-wallet-app/'
                )
              "
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="500"
            >
              <div class="learn-more-btn-content">
                <div class="learn-more-btn-content__title">Learn more</div>
                <div class="learn-more-btn-content__img">
                  <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
                </div>
              </div>
            </button>
          </div>
          <div
            class="landing-page__interest__contents__right__hand"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="10"
          >
            <img src="assets/images/Hand.webp" alt="" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="card__service" #cardServiceDiv>
    <div class="card__service__contents content-container">
      <div class="card__service__contents__left">
        <div
          class="card__service__contents__left__heading ime-heading-1 ime-hide-only-on-mobile"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          Free Virtual & <br />Physical
          <span class="ime-color-blue">VISA </span>
          <span class="ime-color-red">Card</span>
        </div>
        <div
          class="card__service__contents__left__heading ime-heading-1 ime-show-only-on-mobile"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="500"
          data-aos-delay="200"
        >
          Free Virtual & Physical <span class="ime-color-blue">VISA </span>
          <span class="ime-color-red">Card</span>
        </div>
        <div
          class="card__service__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="700"
          data-aos-delay="900"
        >
          Packed with unlimited possibilities, the IME Pay Visa Card goes where
          you go, & brings your IME Pay balance along. Online & offline payments
          made much easier.
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="900"
          data-aos-delay="1500"
          [routerLink]="routerPath.PAY_THROUGH_CARD"
          routerLinkActive="router-link-active"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">Learn more</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>
      <div class="card__service__contents__right">
        <div
          class="card__service__contents__right__images card__service__right__images"
        >
          <img
            src="assets/images/visa-card-no-shadow.webp"
            alt=""
            class="card__service__contents__right__images__card"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="500"
            data-aos-delay="700"
            aTilt
            [tiltSettings]="tiltSettings"
          />
          <img
            src="assets/images/statements.webp"
            alt=""
            class="card__service__contents__right__images__statement"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="700"
            data-aos-delay="800"
            aTilt
            [tiltSettings]="tiltSettings"
          />
        </div>
      </div>
    </div>
  </div>

  <div class="scan__service" #cardScanDiv>
    <div class="scan__service__contents scan__service content-container">
      <div class="scan__service__contents__right">
        <div class="scan__service__contents__right__images">
          <img
            src="assets/images/scan-ime-fone.webp"
            class="scan__service__contents__right__images__fone"
            alt=""
            data-aos="fade-up-right"
            data-aos-easing="ease-out-sine"
            data-aos-duration="300"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/scan-ime-hand.webp"
            class="scan__service__contents__right__images__scan-ime"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="300"
            data-aos-delay="300"
          />
          <img
            src="assets/images/scan-union.webp"
            class="scan__service__contents__right__images__union"
            alt=""
            data-aos="fade-up-right"
            data-aos-easing="ease-out-sine"
            data-aos-duration="500"
            data-aos-delay="600"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <div class="scan__service__contents__right__images__container">
            <img
              src="assets/images/scan-ime-qr.webp"
              class="scan__service__contents__right__images__scan-qr"
              alt=""
              data-aos="fade-up-left"
              data-aos-easing="ease-out-sine"
              data-aos-duration="500"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
      <div class="scan__service__contents__left">
        <div
          class="scan__service__contents__left__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="500"
        >
          <span>Scan any QR</span> <br />literally "any"
        </div>
        <div
          class="scan__service__contents__left__text ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="700"
        >
          Not just IME Pay’s QR, we allow users to scan literally any QR like
          bank’s, merchant’s or any general QR available. <br />You name it, we
          scan it!
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="900"
          [routerLink]="routerPath.PAY_IN_STORE"
          routerLinkActive="router-link-active"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">Learn more</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>
    </div>
  </div>
  <div class="with-imepay" #withImeDiv>
    <div class="with-imepay__container content-container">
      <div class="with-imepay__container__left">
        <div
          class="with-imepay__container__left__heading ime-heading-4"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="300"
        >
          <span class="ime-color-red">Pay Business</span> <br />with IME Pay
        </div>
        <div class="with-imepay__container__left__content">
          <div class="with-imepay__container__left__content__item">
            <div
              class="with-imepay__container__left__content__heading ime-heading-3"
              data-aos="fade-up-right"
              data-aos-easing="ease-out-sine"
              data-aos-duration="400"
              data-aos-delay="600"
            >
              Pay in stores
            </div>
            <div
              class="with-imepay__container__left__content__sub-heading ime-text-2--alt"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="400"
              data-aos-delay="600"
            >
              No digging for your wallet. Just pay touch-free with IME Pay QR.
            </div>
          </div>
          <div class="with-imepay__container__left__content__item">
            <div
              class="with-imepay__container__left__content__heading ime-heading-3"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="300"
              data-aos-delay="1300"
            >
              Pay in apps & online
            </div>
            <div
              class="with-imepay__container__left__content__sub-heading ime-text-2--alt"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="300"
              data-aos-delay="1300"
            >
              Sail through checkout with money you have in your IME Pay wallet.
            </div>
          </div>
        </div>
        <div class="with-imepay__container__left__button">
          <button
            mat-stroked-button
            class="learn-more-btn"
            (mouseover)="
              imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
            "
            (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="900"
            data-aos-delay="1600"
            [routerLink]="routerPath.PAY_ONLINE_IN_APPS"
            routerLinkActive="router-link-active"
          >
            <div class="learn-more-btn-content">
              <div class="learn-more-btn-content__title">Learn more</div>
              <div class="learn-more-btn-content__img">
                <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="with-imepay__container__right">
        <div
          class="with-imepay__container__right__column with-imepay__container__right__column--first"
        >
          <div
            class="with-imepay__container__right__column__normal"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/pay-business-1.webp"
              alt=""
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="600"
              data-aos-delay="700"
              data-aos-once="false"
            />
          </div>
          <div
            class="with-imepay__container__right__column__long"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/pay-business-5.webp"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="900"
              data-aos-delay="500"
              data-aos-once="false"
            />
          </div>
          <div class="with-imepay__container__right__column--first__popup-1">
            <img
              src="assets/images/pay-business-pop-up-1.webp"
              alt=""
              data-aos="fade-up"
              data-aos-duration="300"
              data-aos-delay="1300"
              data-aos-once="false"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
        <div
          class="with-imepay__container__right__column with-imepay__container__right__column--second"
        >
          <div
            class="with-imepay__container__right__column__long"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/pay-business-3.webp"
              alt=""
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="300"
              data-aos-delay="600"
              data-aos-once="false"
            />
          </div>
          <div
            class="with-imepay__container__right__column__normal"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          >
            <img
              src="assets/images/pay-business-4.webp"
              alt=""
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="400"
              data-aos-delay="600"
              data-aos-once="false"
            />
          </div>
          <div class="with-imepay__container__right__column--second__popup-2">
            <img
              src="assets/images/pay-business-pop-up-2.webp"
              alt=""
              data-aos="fade-up"
              data-aos-duration="900"
              data-aos-delay="1600"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-grow-business" #growBusinessDiv>
    <div class="grow-business grow-business--alt content-container">
      <div class="grow-business__top">
        <img
          src="assets/images/Cart.webp"
          alt=""
          class="grow-business__top__cart"
          data-aos="fade-up-right"
          data-aos-easing="ease-out-sine"
          data-aos-duration="400"
        />
        <img
          src="assets/images/paid-for-jacket-popup.webp"
          alt=""
          class="grow-business__top__popup"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="500"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
        <div
          class="grow-business__top__image"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="500"
          data-aos-delay="600"
        >
          <div class="grow-business__top__image__img">
            <img
              src="assets/images/grow-business.webp"
              loading="eager"
              alt=""
            />
          </div>
        </div>
      </div>
      <div class="grow-business__bottom">
        <div class="grow-business__bottom__left">
          <div
            class="grow-business__bottom__left__heading ime-heading-1"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="300"
          >
            Grow your <br />
            <span class="ime-color-red">Business</span>
          </div>
        </div>
        <div class="grow-business__bottom__right">
          <div
            class="grow-business__bottom__right__text ime-text-1"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="700"
            data-aos-delay="700"
          >
            Accept payments in your app or web-site, become a merchant to get
            free QR Kit for your physical store — customers will pay the same
            easy way they pay friends on IME Pay.
          </div>
          <button
            mat-stroked-button
            class="learn-more-btn"
            (mouseover)="
              imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
            "
            (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="900"
            data-aos-delay="800"
            [routerLink]="routerPath.COMING_SOON"
            routerLinkActive="router-link-active"
          >
            <div class="learn-more-btn-content">
              <div class="learn-more-btn-content__title">Learn more</div>
              <div class="learn-more-btn-content__img">
                <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-do-more" #doMoreDiv>
    <div class="container-do-more__content content-container">
      <div
        class="container-do-more__content__title ime-heading-1"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="300"
      >
        {{ moreWithIme?.title }}
      </div>
      <div class="container-do-more__content__contents">
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="500"
        >
          <div class="card__image redeemImg">
            <img [src]="moreWithIme.item1.imageUrl" alt="" />
          </div>
          <div class="gift-icon redeemImg">
            <img src="assets/images/Gift_Left.webp" alt="" srcset="" />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">
              {{ moreWithIme?.item1.header }}
            </div>
            <div class="card__text ime-text-2">
              {{ moreWithIme?.item1.text }}
            </div>
            <div
              class="card__button"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="500"
            ></div>
          </div>
        </div>
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
        >
          <div class="card__image mobileTopUpImg">
            <img [src]="moreWithIme?.item2.imageUrl" alt="" />
          </div>
          <div class="bell-icon">
            <img src="assets/icon/bell.png" alt="" srcset="" />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">
              {{ moreWithIme?.item2.header }}
            </div>
            <div class="card__text ime-text-2">
              {{ moreWithIme?.item2.text }}
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1500"
        >
          <div class="card__image handImg">
            <img [src]="moreWithIme?.item3.imageUrl" alt="" />
          </div>
          <div class="hand-icon">
            <img src="assets/images/hand-like.webp" alt="" srcset="" />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">
              {{ moreWithIme?.item3.header }}
            </div>
            <div class="card__text ime-text-2">
              {{ moreWithIme?.item3.text }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-slider">
    <div class="container-slider__content">
      <div class="container-slider__header">
        <div class="container-slider__header__left">
          <div class="ime-heading-4" data-aos="fade-in">Watch the basics!</div>
          <div
            class="container-slider__text ime-heading-5"
            data-aos="fade-up"
            data-aos-delay="300"
            data-aos-duration="600"
          >
            Get quick solutions to your IME Pay app queries.
          </div>
        </div>
        <div class="container-slider__header__right">
          <img src="assets/icon/youtube-watch.png" class="icon-msg" alt="" />
        </div>
      </div>
      <div class="container-slider__carousel">
        <owl-carousel-o [options]="customOptionsQuestions" #owlElement>
          <ng-template
            carouselSlide
            *ngFor="let slide of slidesCollection; let i = index"
          >
            <div
              class="card-container"
              [style.background-color]="slide.backgroundColor"
            >
              <img
                [src]="slide.imageUrl"
                [ngClass]="slide?.className"
                alt=""
                srcset=""
                class="card-container__image"
              />
              <div class="card-container__info">
                <div class="card-container__info__text">
                  <h3>{{ slide?.text }}</h3>
                </div>
                <div
                  class="card-container__info__button"
                  (mouseover)="changeButton('hovered', i)"
                  (mouseout)="changeButton('not-hovered', i)"
                  (click)="sendUrl(slide?.videoLink, slide?.text)"
                >
                  <img
                    [src]="
                      slide.hovered ? slide?.blackButton : slide?.buttonImage
                    "
                    alt=""
                  />
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>

      <div class="nav-buttons ime-hide-only-on-mobile">
        <div
          class="nav-button nav-buttons__prev"
          (click)="owlElement.prev()"
          (mouseenter)="highLightIcon('navArrowPrevSrcImg')"
          (mouseleave)="blurIcon('navArrowPrevSrcImg')"
        >
          <img
            [src]="navArrowPrevSrcImg"
            class="nav-button__icon prevIcon"
            alt=""
          />
        </div>
        <div
          class="nav-button nav-buttons__next"
          (click)="owlElement.next()"
          (mouseenter)="highLightIcon('navArrowFrontSrcImg')"
          (mouseleave)="blurIcon('navArrowFrontSrcImg')"
        >
          <img
            [src]="navArrowFrontSrcImg"
            class="nav-button__icon nextIcon"
            alt=""
          />
        </div>
      </div>
    </div>
  </div>

  <div class="landing-page-footer" #moreWithDiv>
    <div class="landing-page-footer__background">
      <div class="landing-page-footer__container content-container">
        <div class="landing-page-footer__left">
          <div class="landing-page-footer__left__header ime-heading-7">
            <span class="">It's like money <br /> </span>
            <span class="ime-color-red"> in your pocket </span>
          </div>
          <div class="get-app__button">
            <ime-btn-get-app [data]="buttonRequired"></ime-btn-get-app>
          </div>
        </div>
        <div class="landing-page-footer__right">
          <div class="landing-page-footer__right__container">
            <img
              src="assets/images/plane.webp"
              class="landing-page-footer__right__plane"
              alt=""
              data-aos="fade-down"
              data-aos-delay="800"
              data-aos-easing="fade-right"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/footer-home-section.webp"
              class="landing-page-footer__right__home-section"
              alt=""
              data-aos="fade-down"
              data-aos-delay="600"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettings"
            />
            <img
              src="assets/images/footer-visacard_cta.webp"
              class="landing-page-footer__right__visa-card"
              alt=""
              data-aos="fade-down"
              data-aos-delay="400"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/footer-card-section.webp"
              class="landing-page-footer__right__card-section"
              alt=""
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-easing="ease-in-quart"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettings"
            />
            <img
              src="assets/images/Gift_Left.webp"
              class="landing-page-footer__right__gift"
              alt=""
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div #footerDiv class="footer-section">
    <ime-footer></ime-footer>
  </div>
</section>

<!-- <div
  [ngClass]="scanQrClass"
  *ngIf="showQrCode"
  (click)="toggleSlideQrCode()"
  (mouseover)="showArrow()"
  (mouseout)="hideArrow()"
>
  <div [ngClass]="qrArrowContainerClass">
    <div class="arrow-container__left">◀</div>
    <div class="arrow-container__right">▶</div>
  </div>
  <div class="qrContent">
    <div class="qrContent__close" (click)="showQrCode = false">
      <div class="qrContent__close__img">
        <img src="assets/icon/close.svg" alt="" />
      </div>
    </div>
    <div class="qrContent__img">
      <img src="assets/images/get-app-scan-qr.webp" alt="" />
    </div>
    <p>Scan to get the app.</p>
  </div>
</div> -->

<!-- <div class="container-ads">

  <ime-ads-banner></ime-ads-banner>
</div> -->
