<section class="bank-transfer">
  <div class="bank-transfer__remit">
    <div class="bank-transfer__remit__contents content-container">
      <div class="bank-transfer__remit__contents__left">
        <div
          class="bank-transfer__remit__contents__left__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="800"
          data-aos-delay="200"
        >
          Banking made <br />
          <span
            class="ime-color-red"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="800"
            data-aos-delay="300"
            >easier</span
          >
        </div>
        <div
          class="bank-transfer__remit__contents__left__title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="900"
          data-aos-delay="400"
        >
          IME Pay makes instant bank deposits to any banks & financial
          institutions easy. Linking your bank to IME Pay helps you manage all
          of your finances right from your app by providing free withdrawals to
          linked banks.
        </div>
        <div
          class="bank-transfer__remit__contents__left__button"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
        </div>
      </div>
      <div class="bank-transfer__remit__contents__right">
        <div class="bank-transfer__remit__contents__right__img">
          <div class="bank-transfer__remit__contents__right__img__main">
            <img
              src="assets/images/bank-easier.png"
              class="bank-transfer-hero-banner"
              alt=""
              data-aos="zoom-in-down"
              data-aos-easing="ease-in-out-cubic"
              data-aos-duration="1000"
              data-aos-delay="500"
              aTilt
              [tiltSettings]="tiltSettingsMainImage"
            />
          </div>
          <img
            src="assets/images/deposit-success-no-pig.png"
            class="hero-section-icon__deposit-success"
            alt=""
            data-aos="zoom-in-right"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="800"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/icon/pig-no-fee.png"
            class="hero-section-icon__pig"
            alt=""
            data-aos="zoom-in-left"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="1300"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <div class="hero-section-icon__box ime-hide-only-on-mobile"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="bank-transfer__recieve content-container">
    <div class="bank-transfer__recieve__info">
      <div class="bank-transfer__recieve__info__content">
        <div
          class="bank-transfer__recieve__info__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="900"
          data-aos-delay="100"
        >
          Bank <br />
          <span class="ime-color-red">transfer</span>
        </div>
        <div
          class="bank-transfer__recieve__info__title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          Move money between IME Pay and your bank account, so your money is
          exactly where you want it, when you want it.
        </div>
      </div>
    </div>
    <div class="bank-transfer__recieve__figure">
      <div class="bank-transfer__recieve__figure__img">
        <img
          src="assets/images/bank-transfer.webp"
          class="bank-transfer__recieve__figure__img__main"
          alt=""
          data-aos="zoom-in-down"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="300"
          aTilt
          [tiltSettings]="tiltSettingsMainImage"
        />
        <div
          class="bank-transfer__recieve__figure__icon-search-bank"
          data-aos="zoom-in-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1200"
          data-aos-delay="800"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        >
          <ime-json-animator
            [options]="animationIconOptionBank"
          ></ime-json-animator>
        </div>
      </div>
    </div>
    <div class="bank-transfer__recieve__method">
      <div class="bank-transfer__recieve__method__steps steps">
        <div class="steps__step">
          <div
            class="steps__step__header ime-heading-6"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="500"
          >
            Instant Deposits
          </div>
          <div
            class="steps__step__info ime-text-3--alt"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="800"
          >
            to all the banks available in Nepal.
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header ime-heading-6"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="1500"
          >
            Free Transfers
          </div>
          <div
            class="steps__step__info ime-text-3--alt"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="1800"
          >
            lets you deposit to your linked bank accounts without any charges.
          </div>
        </div>
        <div class="steps__step">
          <div
            class="steps__step__header ime-heading-6"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="2500"
          >
            Check Balance*
          </div>
          <div
            class="steps__step__info ime-text-3--alt"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="2800"
          >
            of your linked bank account right from your app.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bank-link-steps content-container">
    <div
      class="bank-link-steps__header ime-heading-1"
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="800"
      data-aos-delay="100"
    >
      Linking Bank is simple
    </div>
    <div class="bank-link-steps__container steps">
      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="200"
      >
        <div class="steps__step__icon">
          <img
            src="assets/icon/bank-3d.png"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
            alt=""
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--first ime-heading-3"
          >
            Find Bank
          </div>
          <div class="steps__step__info ime-text-1">
            Go to “Link Bank” option and choose your bank.
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="500"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/bank-details.png"
            class="steps-icon-enter-details"
            alt=""
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--second ime-heading-3"
          >
            Account details
          </div>
          <div class="steps__step__info ime-text-1">
            Fill out your bank account name and numbers from the app.
          </div>
        </div>
      </div>

      <div
        class="steps__step"
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="800"
        data-aos-delay="800"
      >
        <div class="steps__step__icon">
          <img
            src="assets/images/link-bank.png"
            class="steps-icon-link"
            alt=""
          />
        </div>
        <div class="steps__step__text">
          <div
            class="steps__step__header steps__step__header--third ime-heading-3"
          >
            Verify
          </div>
          <div class="steps__step__info ime-text-1">
            If your IME Pay’s & Bank’s mobile number matches, we will send a
            verification code.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="bank-transfer__track">
    <div class="bank-transfer__track__container content-container">
      <div class="bank-transfer__track__container__left">
        <div class="bank-transfer__track__container__left__img">
          <img
            src="assets/images/co-operative-transfer.webp"
            class="co-operative-transfer-img"
            alt=""
            data-aos="zoom-in-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="500"
            aTilt
            [tiltSettings]="tiltSettingsMainImage"
          />
          <div
            class="co-operative-transfer-icon-coorative-list"
            data-aos="zoom-in-right"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="1500"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          >
            <ime-json-animator
              [options]="animationIconOption"
            ></ime-json-animator>
          </div>
        </div>
      </div>
      <div class="bank-transfer__track__container__right">
        <div class="bank-transfer__track__container__right__content">
          <div
            class="bank-transfer__track__container__right__content__heading ime-heading-1"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="800"
            data-aos-delay="200"
          >
            Co-operative<br /><span class="ime-color-red">transfer</span>
          </div>
          <div
            class="bank-transfer__track__container__right__content__title ime-text-1"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            IME Pay helps to make your savings easier and faster with real time
            deposits toany Co-operatives available in Nepal.
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="micro-finance-container">
    <div class="micro-finance-container__content">
      <div class="micro-finance-container__content__left">
        <div
          class="micro-finance-container__content__left__heading ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          Microfinance<br /><span class="ime-color-red">transfer</span>
        </div>
        <div
          class="micro-finance-container__content__left__subtitle ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1200"
          data-aos-delay="600"
        >
          Instant deposits to any Micro-finance account is now at your
          fingertips. It has never been so easy.
        </div>
      </div>
      <div class="micro-finance-container__content__right">
        <div class="micro-finance-container__content__right__img">
          <img
            src="assets/images/micro-finance-transfer.png"
            alt=""
            data-aos="zoom-in-left"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="200"
            class="micro-finance-transfer-main-img"
            aTilt
            [tiltSettings]="tiltSettingsMainImage"
          />
          <img
            src="assets/icon/global-icon-tick.png"
            class="micro-finance-transfer-icon-check"
            alt=""
            data-aos="zoom-in-down"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="500"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="bank-transfer__partners ime-hide-only-on-mobile">
    <div class="bank-transfer__partners__contents content-container">
      <div class="bank-transfer__partners__left">
        <div
          class="bank-transfer__partners__left__header"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          Partner Institutions
        </div>
        <div
          class="bank-transfer__partners__left__types"
          data-aos="fade-up-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="500"
        >
          Commercial Banks
        </div>
        <div
          class="bank-transfer__partners__left__types"
          data-aos="fade-up-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="800"
        >
          Development Banks
        </div>
        <div
          class="bank-transfer__partners__left__types"
          data-aos="fade-up-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="1100"
        >
          Finance Companies
        </div>
      </div>
      <div class="bank-transfer__partners__right">
        <div
          class="bank-transfer__partners__right__heading ime-heading-2"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="200"
        >
          You won’t miss your accounts anymore.
        </div>
        <div
          class="bank-transfer__partners__right__text ime-text-2--alt"
          data-aos="fade-up-right"
          data-aos-easing="ease-in-out-cubic"
          data-aos-duration="1200"
          data-aos-delay="600"
        >
          Managing the money you have in IME Pay is no sweat. We give you the
          tools you need to move money and adjust your balance the way you want.
        </div>
      </div>
    </div>
    <div class="bank-transfer__partners__icons">
      <img
        *ngFor="let image of allBankFinance; let i = index"
        [src]="image"
        alt=""
        srcset=""
        data-aos="fade-left"
        data-aos-easing="ease-in-out-quart"
        data-aos-duration="500"
        [attr.data-aos-delay]="i * 50 + 50"
      />
    </div>
  </div>
  <div class="partners content-container ime-show-only-on-mobile">
    <div class="partners__contents">
      <div class="partners__contents__info">
        <div class="partners__contents__title ime-heading-2">
          You won’t miss your accounts anymore.
        </div>
        <div class="partners__contents__label ime-text-2--alt">
          Managing the money you have in IME Pay is no sweat. We give you the tools you need to move money and adjust your balance the way you want.
        </div>
      </div>
      <div class="partners__contents__commercial-patners commercial-patners">
        <div class="commercial-patners__subtitle">Commercial Banks</div>
        <div class="commercial-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template carouselSlide *ngFor="let slide of comBanks">
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="partners__contents__finance-patners finance-patners">
        <div class="finance-patners__subtitle">Development Banks</div>
        <div class="finance-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template carouselSlide *ngFor="let slide of devBanks">
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
      <div class="partners__contents__finance-patners finance-patners">
        <div class="finance-patners__subtitle">Financial Companies</div>
        <div class="finance-patners__icon-content icon-content">
          <owl-carousel-o [options]="customOptions" #owlCar>
            <ng-template carouselSlide *ngFor="let slide of finances">
              <div class="patner-icon-container">
                <img [src]="slide" alt="" srcset="" />
              </div>
            </ng-template>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>

  <div class="container-hidden-fee content-container">
    <div class="container-hidden-fee__box">
      <div class="container-hidden-fee__box__title"
      data-aos="custom-zoom-in"
      data-aos-delay="600"
      data-aos-duration="400"
      >
        No Hidden fees
      </div>
      <img
        src="assets/icon/pig-no-fee.png"
        class="icon__pig"
        alt=""
        data-aos="zoom-in-left"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="700"
        data-aos-duration="600"
      />
      <div
        class="container-hidden-fee__box__subtitle ime-text-4"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="850"
        data-aos-duration="600"
      >
        It costs nothing to link your bank or load and deposit money using your
        IME Pay wallet.
      </div>
      <div
        class="container-hidden-fee__box__learn-more-link"
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="1000"
        data-aos-duration="600"
      >
        <a  [href]="commision_link" target="_blank">LEARN MORE</a>
      </div>
      <div class="rupees-section">
        <div
          class="rupees-section__wallet-transfer"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-cubic"
          data-aos-delay="1150"
          data-aos-duration="600"
        >
          <span class="charge-rs">Rs. 0</span>
          <p>Link bank</p>
        </div>
        <div
          class="rupees-section__remmittance"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-cubic"
          data-aos-delay="1300"
          data-aos-duration="300"
        >
          <span class="charge-rs">Rs. 0</span>
          <p>Add money</p>
        </div>
        <div
          class="rupees-section__bank-deposit"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-cubic"
          data-aos-delay="1450"
          data-aos-duration="300"
        >
          <span class="charge-rs">Rs. 0</span>
          <p>Linked bank deposit*</p>
        </div>
      </div>
      <span
        data-aos="fade-up"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="1550"
        data-aos-duration="600"
        class="ime-hide-only-on-mobile"
      >
        *IME Pay does not charge for sending money to a linked bank account.
        There is a fee for sending money to bank accounts which are not linked
        on IME Pay.
      </span>
      <img
        src="assets/images/Hand.webp"
        class="icon__hand"
        alt=""
        data-aos="zoom-in-left"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="1700"
        data-aos-duration="600"
      />
      <img
        src="assets/images/plane.webp"
        class="icon__plane ime-hide-only-on-mobile"
        alt=""
        data-aos="zoom-in-right"
        data-aos-easing="ease-in-out-cubic"
        data-aos-delay="1850"
        data-aos-duration="600"
      />
    </div>
  </div>

  <div class="container-life-easy">
    <div class="container-life-easy__background">
      <div class="container-life-easy__container content-container">
        <div class="container-life-easy__left">
          <img
            src="assets/icon/pig.png"
            class="container-life-easy__left__pig"
            alt=""
            data-aos="fade-left"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="800"
          />
          <div
            class="container-life-easy__left__header ime-heading-7 ime-hide-only-on-mobile"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
          >
            <span class="ime-color-red">Link</span> your <br />bank now
          </div>
          <div
            class="container-life-easy__left__header ime-heading-7 ime-show-only-on-mobile"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1000"
          >
            <span class="ime-color-red">Link</span> your bank <br />now
          </div>
          <div
            class="get-app__button"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="1200"
            data-aos-delay="500"
          >
            <ime-btn-get-app [data]="{ getApp: true }"></ime-btn-get-app>
          </div>
        </div>
        <div class="container-life-easy__right">
          <div class="container-life-easy__right__container">
            <img
              src="assets/images/link-now-popup.webp"
              class="container-life-easy__right__main-1"
              alt=""
              data-aos="fade-down"
              data-aos-easing="ease-in-out-cubic"
              data-aos-duration="1200"
              data-aos-delay="1000"
              aTilt
              [tiltSettings]="tiltSettingsMainImage"
            />
            <img
              src="assets/images/mobile-cta-bank-2.webp"
              class="container-life-easy__right__main-2"
              alt=""
              data-aos="fade-up"
              data-aos-easing="ease-in-out-cubic"
              data-aos-duration="1200"
              data-aos-delay="1500"
              aTilt
              [tiltSettings]="tiltSettingsMainImage"
            />
            <img
              src="assets/icon/link.png"
              class="container-life-easy__right__link"
              alt=""
              data-aos="zoom-in"
              data-aos-easing="ease-in-out-cubic"
              data-aos-duration="1200"
              data-aos-delay="1800"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</section>
