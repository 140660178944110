<div class="button-container">
  <a
    *ngIf="data?.login"
    mat-stroked-button
    class="login-button"
    href="https://services.imepay.com.np/"
    color="primary"
  >
    <div class="login-button__text">Agent Login</div>
</a>

  <button
    *ngIf="data?.getApp"
    mat-stroked-button
    class="get-app-btn"
    (mouseenter)="playAnimation(true)"
    (mouseleave)="playAnimation(false)"
    (click) = "detectMobile()"
  >
    <span class="get-app-btn__content">
      <span class="download-gif">
        <ng-lottie
          [options]="downloadAnimationIconOption"
          (animationCreated)="animationCreated($event)"
        ></ng-lottie>
      </span>
      <span class="text"> &nbsp;GET APP </span>
    </span>
  </button>
</div>
