<div class="page-how-it-works">
  <div class="container-how-it-works">
    <div class="container-how-it-works__background">
      <div class="container-how-it-works__background-text marquee">
        <span>Just say “IME Pay me”.</span>
      </div>
    </div>
    <section class="how-it-works content-container">
      <div
        class="how-it-works__get-app ime-text-1--alt"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="800"
        data-aos-delay="100"
      >
        <span class="ime-hide-only-on-mobile">
          Pay & get paid with a tap, from anyone, anywhere. Receive remittance
          from friends and family around the world.
          <br /><br />No fees, just ease.
        </span>
        <span class="ime-show-only-on-mobile">
          Pay & get paid with a tap, from anyone, anywhere. Receive remittance
          from friends and family around the world.
          <br /><br />No fees, just ease.
        </span>
        <div
          class="how-it-works__get-app__buttons"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1200"
          data-aos-delay="300"
        >
          <ime-btn-get-app [data]="buttonRequired"></ime-btn-get-app>
        </div>
      </div>
      <div class="how-it-works__banner">
        <div class="how-it-works__banner__img"
        >
          <img
            src="assets/images/how-it-works-banner-1.webp"
            class="how-it-works__banner__img__first"
            alt=""
            data-aos="fade-down"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1200"
            data-aos-delay="100"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/how-it-works-banner-2.webp"
            class="how-it-works__banner__img__second"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1200"
            data-aos-delay="100"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/plane.webp"
            class="how-it-works__banner__img__icon-plane"
            alt=""
            data-aos="plane-move-left"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1200"
            data-aos-delay="500"
          />
          <img
            src="assets/images/hand-like.webp"
            class="how-it-works__banner__img__icon-hand-like"
            alt=""
            data-aos="zoom-in-hand"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1200"
            data-aos-delay="1500"
          />
          <img
            src="assets/images/how-it-works-banner-3.webp"
            class="how-it-works__banner__img__third"
            alt=""
            data-aos="fade-down-left"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1200"
            data-aos-delay="500"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
        </div>
      </div>
    </section>
  </div>
  <div class="container-lifestyle-wallet">
    <div class="container-lifestyle-wallet__contents content-container">
      <span class="container-lifestyle-wallet__contents__title ime-heading-1">
        A Lifestyle Wallet
      </span>
      <ime-lifestyle-wallet-card [data]="this.data"></ime-lifestyle-wallet-card>
    </div>
  </div>
  <div class="container-load-money">
    <div class="container-load-money__contents content-container">
      <div class="container-load-money__contents__left">
        <div
          class="container-load-money__contents__left__title ime-heading-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1300"
          data-aos-anchor-placement="top-bottom"
        >
          <span class="ime-color-red">Add</span><br />Money
        </div>
        <div
          class="container-load-money__contents__left__label ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1300"
          data-aos-delay="200"
          data-aos-anchor-placement="top-bottom"
        >
          Just add more to your wallet using Mobile/Internet banking,
          Debit/Credit cards or visit nearby agents to convert your cash into
          IME Pay balance.
        </div>
        <button
          mat-stroked-button
          class="learn-more-btn"
          (mouseover)="
            imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
          "
          (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1300"
          data-aos-delay="400"
          data-aos-anchor-placement="top-bottom"
          (click)="goToPage(bankTransferPath)"
        >
          <div class="learn-more-btn-content">
            <div class="learn-more-btn-content__title">Learn more</div>
            <div class="learn-more-btn-content__img">
              <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
            </div>
          </div>
        </button>
      </div>
      <div class="container-load-money__contents__middle">
        <img
          src="assets/icon/piggy.webp"
          class="load-money-icons__icon-pig"
          alt=""
        />
        <img
          src="assets/icon/silver-coin.png"
          class="load-money-icons__coin"
          alt=""
        />
        <img
          src="assets/images/add-money_712_1356.webp"
          class="container-load-money__contents__middle__addMoneyImg"
          alt=""
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
          aTilt
          [tiltSettings]="tiltSettingsPopupImage"
        />
      </div>

      <div class="container-load-money__contents__right">
        <div
          class="container-load-money__contents__right__linked-bank sub-block"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1000"
        >
          <div class="subtitle ime-heading-6">Linked Bank</div>
          <div class="label ime-text-3">
            lets you add balance directly to your wallet with your saved bank
            details.
          </div>
        </div>
        <div
          class="container-load-money__contents__right__mobile-ebanking sub-block"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1300"
          data-aos-delay="200"
        >
          <div class="subtitle ime-heading-6">Mobile & E-banking</div>
          <div class="label ime-text-3">
            provides easy option to transfer balance to IME Pay inside the
            bank’s app or login your bank from IME pay to add.
          </div>
        </div>
        <div
          class="container-load-money__contents__right__debit-card sub-block"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="1300"
          data-aos-delay="400"
        >
          <div class="subtitle ime-heading-6">
            Debit/Credit Card & Connect IPS
          </div>
          <div class="label ime-text-3">
            helps you maintain your balance on the move by adding money from a
            verified bank account right into IME Pay.
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="container-manage-favourite content-container">
    <div class="favourite-text">
      <div
        class="favourite-text__title ime-heading-1"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="1000"
        data-aos-delay="100"
      >
        Manage your<br />
        <span class="ime-color-red">Favourites</span>
      </div>
      <span
        class="favourite-text__label ime-text-1"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="1200"
        data-aos-delay="300"
        >Our new Favourites feature easily lets you keep track of all your
        frequent transactions in one place and get fast access.</span
      >
    </div>
    <div class="favourite-img">
      <div class="favourite-img__images">
        <div class="favourite-img__images__mainImg">
          <img
            src="assets/images/manage-your-favourites.webp"
            alt=""
            class="img-mobile"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="100"
            data-aos-anchor-placement="top-bottom"
          />

          <img
            src="assets/images/favourites.webp"
            class="img-favourities"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="300"
            data-aos-anchor-placement="top-bottom"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />

          <img
            src="assets/icon/heart.png"
            class="icon-heart"
            alt=""
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="1000"
            data-aos-delay="300"
            data-aos-anchor-placement="top-bottom"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="container-safer-pay content-container">
    <div class="container-safer-pay__left">
      <div class="container-safer-pay__left__images">
        <img
          src="assets/images/safer-way-to-pay_1039_928.webp"
          class="container-safer-pay__left__safer-way-img"
          alt=""
          srcset=""
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="400"
          data-aos-anchor-placement="top-bottom"
          (mouseover)="lockhover()"
          (mouseout)="imgSrcLock = 'assets/icon/lock-locked.png'"
        />
        <img
          [src]="imgSrcLock"
          class="container-safer-pay__left__lock"
          alt=""
          (mouseover)="lockhover()"
        />
      </div>
    </div>
    <div class="container-safer-pay__right">
      <span
        class="container-safer-pay__right__title ime-heading-1"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="400"
        data-aos-anchor-placement="top-bottom"
      >
        A safer way <br /><span class="ime-color-red">to pay</span>
      </span>
      <span
        class="container-safer-pay__right__label ime-text-1"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="700"
        data-aos-anchor-placement="top-bottom"
      >
        Our PCIDSS certified system keeps your card & payments details safe, and
        ask for extra verification to protect you. Our 3D Secured card help
        fight fraud & your money is safeguarded by licensed banks.
      </span>
    </div>
  </div>

  <div class="container-hidden-fee content-container">
    <div class="container-hidden-fee__box">
      <div class="container-hidden-fee__box__title ime-hide-only-on-mobile"
      data-aos="custom-zoom-in"
      data-aos-delay="400"
      data-aos-duration="1000"
      >
        No Hidden fees
      </div>
      <div
        class="container-hidden-fee__title ime-show-only-on-mobile"
        data-aos="custom-zoom-in"
        data-aos-easing="ease-out-sine"
        data-aos-duration="500"
      >
        No Hidden fees
      </div>
      <img src="assets/icon/pig-no-fee.png" class="icon__pig" alt="" />
      <div
        class="container-hidden-fee__box__subtitle ime-text-4"
        data-aos="fade-up"
        data-aos-easing="ease-out-sine"
        data-aos-duration="800"
        data-aos-delay="1300"
        data-aos-anchor-placement="top-bottom"
      >
        It costs nothing to send or receive money using what’s in your IME Pay
        account or any bank account.
      </div>
      <div class="container-hidden-fee__box__learn-more-link">
        <a
          [href]="commision_link"
          target="_blank"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="800"
          data-aos-delay="1400"
          data-aos-anchor-placement="top-bottom"

          >LEARN MORE</a
        >
      </div>
      <div class="rupees-section">
        <div
          class="rupees-section__wallet-transfer"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
          data-aos-delay="1500"
          data-aos-anchor-placement="top-bottom"
        >
          <span class="charge-rs">Rs. 0</span>
          <p>Wallet Transfer</p>
        </div>
        <div
          class="rupees-section__remmittance"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1200"
          data-aos-delay="1600"
          data-aos-anchor-placement="top-bottom"
        >
          <span class="charge-rs">Rs. 0</span>
          <p>Remittance*</p>
        </div>
        <div
          class="rupees-section__bank-deposit"
          data-aos="zoom-in-left"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1500"
          data-aos-delay="1700"
          data-aos-anchor-placement="top-bottom"
        >
          <span class="charge-rs">Rs. 0</span>
          <p>Linked Bank Deposit*</p>
        </div>
      </div>
      <span
        class="container-hidden-fee__box__bottom-text"
      >
      <br>
      <div
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="1000"
      data-aos-delay="1800"
      data-aos-anchor-placement="top-bottom"
      class="container-hidden-fee__box__bottom-text__term1">
        *Remittance receiver has to pay if he/she withdraws cash from our agent
        network. Receiving remittance on mobile wallet is always free.
      </div> <br>
      <div
      data-aos="fade-up"
      data-aos-easing="ease-in-out-quart"
      data-aos-duration="1000"
      data-aos-delay="1800"
      data-aos-anchor-placement="top-bottom"
      class="container-hidden-fee__box__bottom-text__term2">
        *There is
        a fee for making bank deposits which are not linked on your IME Pay and
        the fees are taken by respective bank themselves.
      </div>
      </span>
      <span class="container-hidden-fee__box__bottom-text-mobile"
        >*T&C Apply</span
      >
      <img
        src="assets/images/Hand.webp"
        class="icon__hand"
        alt=""
        data-aos="fade-left"
        data-aos-easing="ease-out-sine"
        data-aos-duration="600"
        data-aos-delay="900"
      />
      <img
        src="assets/images/plane.webp"
        class="icon__plane"
        alt=""
        data-aos="fade-down"
        data-aos-delay="1100"
        data-aos-easing="fade-right"
        data-aos-duration="1000"
      />
    </div>
  </div>
  <div class="with-imepay">
    <div class="with-imepay__container content-container">
      <div class="with-imepay__container__left">
        <div
          class="with-imepay__container__left__heading ime-heading-4"
          data-aos="fade-up"
          data-aos-easing="ease-out-sine"
          data-aos-duration="300"
        >
          <span class="ime-color-red">Remit </span>with <br />IME Pay
        </div>
        <div class="with-imepay__container__left__content">
          <div class="with-imepay__container__left__content__item">
            <div
              class="with-imepay__container__left__content__heading ime-heading-3"
              data-aos="fade-up-right"
              data-aos-easing="ease-out-sine"
              data-aos-duration="400"
              data-aos-delay="600"
            >
              Send domestic remittance
            </div>
            <div
              class="with-imepay__container__left__content__sub-heading ime-text-2--alt"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="400"
              data-aos-delay="600"
            >
              throughout the country with just a mobile number. Good thing, it's
              free.
            </div>
          </div>
          <div class="with-imepay__container__left__content__item">
            <div
              class="with-imepay__container__left__content__heading ime-heading-3"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="300"
              data-aos-delay="1000"
            >
              Receive worldwide remittance
            </div>
            <div
              class="with-imepay__container__left__content__sub-heading ime-text-2--alt"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="300"
              data-aos-delay="1000"
            >
              directly on to your IME Pay with just a few taps. Just make sure
              that you have the control numbers.
            </div>
          </div>
        </div>
        <div class="with-imepay__container__left__button">
          <button
            mat-stroked-button
            class="learn-more-btn"
            (mouseover)="
              imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow-hover.svg'
            "
            (mouseout)="imgSrcLearMoreArrow = 'assets/icon/learnmore-arrow.svg'"
            data-aos="fade-up"
            data-aos-easing="ease-out-sine"
            data-aos-duration="900"
            data-aos-delay="1200"
            (click)="goToPage(remittancePath)"
          >
            <div class="learn-more-btn-content">
              <div class="learn-more-btn-content__title">Learn more</div>
              <div class="learn-more-btn-content__img">
                <img [src]="imgSrcLearMoreArrow" alt="" srcset="" />
              </div>
            </div>
          </button>
        </div>
      </div>
      <div class="with-imepay__container__right">
        <div
          class="with-imepay__container__right__column with-imepay__container__right__column--first"
        >
          <div class="with-imepay__container__right__column__normal">
            <img
              src="assets/images/remit-1.webp"
              alt=""
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="1300"
              data-aos-delay="500"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/calender.webp"
              alt=""
              class="icon-calendar"
              data-aos="fade-down"
              data-aos-duration="1500"
              data-aos-delay="500"
            />
          </div>
          <div class="with-imepay__container__right__column__long">
            <img
              src="assets/images/remit-mom.webp"
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="1600"
              data-aos-delay="500"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="with-imepay__container__right__column--first__popup-1">
            <img
              src="assets/images/remit-to-mom_642_312.webp"
              alt=""
              data-aos="fade-up"
              data-aos-delay="500"
              data-aos-duration="1600"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
        <div
          class="with-imepay__container__right__column with-imepay__container__right__column--second"
        >
          <div class="with-imepay__container__right__column__long">
            <img
              src="assets/images/remit-3.webp"
              alt=""
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="300"
              data-aos-delay="600"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="with-imepay__container__right__column__normal">
            <img
              src="assets/images/foreign-building_554_544.webp"
              alt=""
              data-aos="fade-up"
              data-aos-easing="ease-out-sine"
              data-aos-duration="400"
              data-aos-delay="600"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/icon/bell.png"
              alt=""
              class="icon-bell"
              data-aos="fade-down"
              data-aos-delay="2000"
            />
          </div>
          <div class="with-imepay__container__right__column--second__popup-2">
            <img
              src="assets/images/remit-receive-quater_642_312.webp"
              alt=""
              data-aos="fade-up"
              data-aos-delay="800"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-pay-like-pro">
    <div class="container-pay-like-pro__content content-container">
      <div class="container-pay-like-pro__content__left">
        <div class="container-pay-like-pro__content__left__images">
          <img
            src="assets/images/pay-like-pro-HIW-1.webp"
            alt=""
            srcset=""
            class="img-front"
            data-aos="fade-up-right"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="100"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <img
            src="assets/images/pay-like-pro-HIW-2.webp"
            alt=""
            srcset=""
            class="img-back"
            data-aos="fade-up-left"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="100"
            aTilt
            [tiltSettings]="tiltSettingsPopupImage"
          />
          <!-- <img
            src="assets/icon/thug-life.webp"
            alt="thug life"
            srcset=""
            class="icon-thuglife"
            data-aos="fade-down"
            data-aos-easing="ease-out-cubic"
            data-aos-duration="1000"
            data-aos-delay="800"
          /> -->
        </div>
      </div>
      <div class="container-pay-like-pro__content__right">
        <div
          class="container-pay-like-pro__content__right__title ime-heading-2"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="800"
          data-aos-delay="100"
        >
          IME Pay like a <span class="ime-color-red">pro</span>
        </div>
        <div
          class="container-pay-like-pro__content__right__sub-title ime-text-1"
          data-aos="fade-up"
          data-aos-easing="ease-in-out-quart"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          There are a lot of Bikash Nahatas out there. Find the right one fast —
          scan his QR code, and up pops his profile. No guesswork involved.
          Always pay the right person.
        </div>
      </div>
    </div>
  </div>

  <div class="container-do-more">
    <div class="container-do-more__content content-container">
      <div class="container-do-more__content__title ime-heading-1">
        Do more with IME Pay
      </div>
      <div class="container-do-more__content__contents">
        <div
          class="card"
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="100"
        >
          <div class="card__image">
            <img
              src="assets/images/do-more-1.webp"
              class="card__image__send-receive"
              alt=""
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Pay in apps & online</div>
            <div class="card__text ime-text-2">
              Your money your way, earn rewards and cashbacks.
            </div>
            <div class="card__button">
              <a
                [routerLink]="['/',routerPath.PAY_ONLINE_IN_APPS]"
              >
                <button mat-button color="primary">Learn More</button>
              </a>
            </div>
          </div>
        </div>

        <div
          class="card"
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="400"
        >
          <div class="card__image">
            <img
              src="assets/images/do-more-2.webp"
              class="card__image__deposit-success"
              alt=""
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Pay in stores</div>
            <div class="card__text ime-text-2">
              Scan QR the same way you scan your friend’s QR on IME Pay.
            </div>
            <div class="card__button">
              <a
              [routerLink]="['/',routerPath.PAY_IN_STORE]"
              >
                <button mat-button color="primary">Learn More</button>
              </a>
            </div>
          </div>
        </div>
        <div
          class="card"
          data-aos="fade-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="1000"
          data-aos-delay="800"
        >
          <div class="card__image">
            <img
              src="assets/images/do-more-3.webp"
              class="card__image__qr-code"
              alt=""
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
          </div>
          <div class="card__info">
            <div class="card__heading ime-heading-5">Get IME VISA Card</div>
            <div class="card__text ime-text-2">
              Take IME Pay balance shopping everywhere VISA® is accepted.
            </div>
            <div class="card__button">
              <a
              [routerLink]="['/',routerPath.PAY_THROUGH_CARD]"
              >
                <button mat-button color="primary">Learn More</button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container-life-easy" #moreWithDiv>
    <div class="container-life-easy__background">
      <div class="container-life-easy__container content-container">
        <div class="container-life-easy__left">
          <div
            class="container-life-easy__left__header ime-heading-7"
            data-aos="fade-up"
            data-aos-easing="ease-in-out-cubic"
            data-aos-duration="700"
            data-aos-delay="100"
          >
            <span class="ime-color-red">Download</span> <br />
            IME Pay Today
          </div>
          <div class="get-app__button">
            <ime-btn-get-app [data]="buttonRequired"></ime-btn-get-app>
          </div>
        </div>
        <div class="container-life-easy__right">
          <div class="container-life-easy__right__container">
            <img
              src="assets/images/plane.webp"
              class="container-life-easy__right__plane"
              alt=""
              data-aos="fade-down"
              data-aos-delay="800"
              data-aos-easing="fade-right"
              data-aos-duration="1000"
            />
            <img
              src="assets/images/footer-home-section.webp"
              class="container-life-easy__right__home-section"
              alt=""
              data-aos="fade-down"
              data-aos-delay="600"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/footer-visacard_cta.webp"
              class="container-life-easy__right__visa-card"
              alt=""
              data-aos="fade-down"
              data-aos-delay="400"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/footer-card-section.webp"
              class="container-life-easy__right__card-section"
              alt=""
              data-aos="fade-down"
              data-aos-delay="200"
              data-aos-easing="ease-in-quart"
              data-aos-duration="1000"
              aTilt
              [tiltSettings]="tiltSettingsPopupImage"
            />
            <img
              src="assets/images/Gift_Left.webp"
              class="container-life-easy__right__gift"
              alt=""
              data-aos="fade-down"
              data-aos-delay="100"
              data-aos-easing="ease-in-out-back"
              data-aos-duration="1000"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="footer-section">
    <ime-footer></ime-footer>
  </div>
</div>
